<template>
  <div>
    <b-row align-h="center" v-if="state === 'initializing'">
      <b-spinner></b-spinner>
    </b-row>

    <b-row class="mb-4" v-if="state !== 'initializing'">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <h4>Job Cards View</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="red" size="sm" squared class="mr-2" @click="goBackToSearch">Cancel</b-button>
              <b-button variant="primary" size="sm" squared @click="openSurveyModal(false)"
                :disabled="isStatusClosed || state === 'loading'">
                <b-spinner small v-if="state === 'loading'"></b-spinner> Save</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col cols="4" class="m-0 p-0">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <label>Company</label>
                  <b-input-group>
                    <b-form-input v-model="selectedJobCard.company" :disabled="true"></b-form-input>
                    <!-- <b-input-group-append>
                      <b-button size="sm" text="Button" variant="secondary" @click="openCompanySearchModal"
                        >Search</b-button
                      >
                    </b-input-group-append> -->
                  </b-input-group>
                </b-col>
                <b-col cols="12" class="mb-2">
                  <label>Experiencing User</label>
                  <b-input-group>
                    <b-form-input v-model="selectedJobCard.client" :disabled="true"></b-form-input>
                    <!-- <b-input-group-append>
                      <b-button size="sm" text="Button" variant="secondary" @click="openClientSearchModal"
                        >Search</b-button
                      >
                    </b-input-group-append> -->
                  </b-input-group>
                </b-col>
                <b-col class="mb-2" cols="12">
                  <label>Location</label>
                  <!-- <b-form-input :disabled="selectedJobCard.client === null" v-model="selectedJobCard.locationId"></b-form-input> -->
                  <!-- <b-form-select v-model="selectedJobCard.locationId" :disabled="true">
                    <b-form-select-option
                      v-for="(item, index) in locationsForUser"
                      :key="index"
                      :value="item.locationId"
                      >{{ item.address }}</b-form-select-option
                    >
                  </b-form-select>
                 -->
                  <b-form-input v-model="selectedJobCard.location" :disabled="true"></b-form-input>
                </b-col>
                <b-col class="mb-2" cols="12">
                  <b-card class="info-card">
                    <b-row>
                      <b-col cols="12">
                        <data-label :data="clientAccountNumber" :labelName="'Account Number:'" :labelRight="false" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <data-label :data="clientEmail" :labelName="'Email:'" :labelRight="false" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <data-label :data="clientContactNumber" :labelName="'Phone Number:'" :labelRight="false" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <data-label :data="clientContactNumberAlt" :labelName="'Alt Phone Number:'" :labelRight="false" />
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="4" class="m-0 p-0">
              <b-row>
                <!-- <b-col cols="12" class="mb-2">
                                    <label>Technician Group</label>
                                    <b-input-group>
                                        <b-form-input disabled v-model="selectedJobCard.technicianGroup"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" text="Button" variant="secondary" @click="openTechnicianGroupModal">Search</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col> -->
                <b-col cols="12" class="mb-2">
                  <label>Technician</label>
                  <b-input-group>
                    <b-form-input disabled v-model="selectedJobCard.technician"></b-form-input>
                    <b-input-group-append>
                      <b-button size="sm" text="Button" variant="secondary" @click="openTechnicianModal" :disabled="isStatusClosed">Search</b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <!-- <b-form-select v-model="selectedJobCard.technicianId">
                                        <b-form-select-option v-for="(item, index) in technicians" :key="index" :value="item.userId">{{item.name}}</b-form-select-option>
                                    </b-form-select> -->
                </b-col>
                <b-col cols="12" class="mb-2">
                  <label>Due Date</label>
                  <!-- <b-input type="text" v-model="selectedJobCard.dueDate" :disabled="true"></b-input> -->


                  <b-input type="text" :value="formatDatetime(selectedJobCard.dueDate)" disabled></b-input>


                </b-col>






                <b-col cols="12" class="mb-2">
                  <label>Priority</label>
                  <b-form-select v-model="selectedJobCard.priorityID" :disabled="true">
                    <b-form-select-option v-for="(item, index) in priority" :key="index" :value="item.id">{{
                      item.description
                    }}</b-form-select-option>
                  </b-form-select>
                </b-col>
                <b-col class="mb-2" cols="12">
                  <b-card class="info-card">
                    <b-row>
                      <b-col cols="12">
                        <data-label :data="selectedJobCard.createdDate | dateTimeFilter" :labelName="'Open Date:'"
                          :labelRight="false" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-row class="data-label">
                          <b-col cols="6" class="text-left ml-0 mr-0 headLabel">SLA Time:</b-col>
                          <b-col cols="6">
                            <b-badge :variant="checkColourForVariant">{{ slaTime }} %</b-badge>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>

            <b-col class="m-0 p-0" cols="4">
              <b-row>
                <b-col cols="12">
                  <label>Subject</label>
                  <b-form-input v-model="selectedJobCard.subject" :disabled="isStatusClosed"></b-form-input>
                </b-col>
                <b-col cols="12" class="mb-2">
                  <label>Status</label>
                  <b-form-select v-model="selectedJobCard.statusId" :disabled="isStatusClosed || state === 'loading'">
                    <b-form-select-option v-for="(item, index) in status" :key="index" :value="item.id" :disabled="isStatusClosed || item.description == 'Closed'">{{
                      item.description
                    }}</b-form-select-option>
                  </b-form-select>
                </b-col>
                <b-col cols="12">
                  <label>Purchase Order Number</label>
                  <b-form-input v-model="selectedJobCard.purchaseOrderNumber" :disabled="isStatusClosed"></b-form-input>
                </b-col>
                <b-col cols="12">
                  <label>Client Reference Number</label>
                  <b-form-input v-model="selectedJobCard.clientReference" :disabled="isStatusClosed"></b-form-input>
                </b-col>

                <b-col cols="12" class="mt-2">
                  <b-card class="info-card">
                    <b-row>
                      <b-col cols="12">
                        <data-label :data="selectedJobCard.referenceNumber" :labelName="'Ticket Number:'"
                          :labelRight="false" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <data-label :data="selectedJobCard.contractType" :labelName="'Contract Type:'"
                          :labelRight="false" />
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4" v-if="state !== 'initializing'">
      <b-col cols="12">
        <b-card no-body>
          <b-tabs v-model="tabIndex" card fill class="m-0">
            <b-tab title="Details" :title-link-class="linkClass(0)">
              <jobcardDetails :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <b-tab title="Stock" :title-link-class="linkClass(1)">
              <stock  :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <b-tab title="Checklist" :title-link-class="linkClass(2)">
              <checklist  :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <b-tab title="Labour" :title-link-class="linkClass(3)">
              <labour :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <b-tab title="Documents" :title-link-class="linkClass(4)">
              <documents :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <!-- <b-tab title="Allocated Material" :title-link-class="linkClass(5)">
              <AllocatedMaterial />
            </b-tab> -->
            <b-tab title="Travel" :title-link-class="linkClass(5)">
              <equipment-and-consumables  :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <b-tab title="Associated Costs" :title-link-class="linkClass(6)">
              <AssociatedCost  :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <b-tab title="Quotation" :title-link-class="linkClass(7)">
              <quote  :isStatusClosed="isStatusClosed"/>
            </b-tab>
            <b-tab title="Survey" :title-link-class="linkClass(8)">
              <survey  :isStatusClosed="isStatusClosed"/>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="search-company-modal" hide-footer size="xl" no-header @close="closeCompanySearchModal">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="companySearchField.name"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2" @click="clearCompanySearchField">Clear</b-button>
          <b-button variant="primary" squared @click="companySearch">Search</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover :items="companyDataTable.dataSource" :fields="companyDataTable.tableColumns"
            :busy="companyDataTable.isLoading" @row-clicked="addCompany" :per-page="companyDataTable.resultsPerPage"
            id="companyModalTable" :current-page="companyDataTable.currentPage" sort-icon-left>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button @click="addCompany(row.item)" size="sm" class="btn-icon">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </b-button>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-pagination v-model="companyDataTable.currentPage" :total-rows="companyDataTableRows"
          :per-page="companyDataTable.resultsPerPage" aria-controls="companyModalTable">
        </b-pagination>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-right">
          <b-button variant="red" class="mr-2" squared @click="closeCompanySearchModal()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="search-client-modal" hide-footer size="xl" no-header @close="closeClientSearchModal">
      <b-row v-if="!showClientContactAdd">
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="contactSearchField.name"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row v-if="!showClientContactAdd">
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2" @click="clearContactSearchField">Clear</b-button>
          <b-button variant="primary" squared @click="doSearchByCompany">Search</b-button>
        </b-col>
      </b-row>

      <div v-if="showClientContactAdd">
        <b-form v-if="state === 'show'">
          <b-row>
            <b-col cols="4">
              <label>Company<span class="text-red">*</span></label>
              <b-form-input disabled v-model="selectedJobCard.company"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Name <span class="text-red">*</span></label>
              <b-form-input v-model="contactValues.name" @blur="$v.contactValues.name.$touch()"></b-form-input>
              <div v-if="$v.contactValues.name.$error" class="text-red font-weight-400 text-left">
                <p v-if="!$v.contactValues.name.required">This is a required field</p>
              </div>
            </b-col>
            <b-col cols="4">
              <label>Surname <span class="text-red">*</span></label>
              <b-form-input v-model="contactValues.surname" @blur="$v.contactValues.surname.$touch()"></b-form-input>
              <div v-if="$v.contactValues.surname.$error" class="text-red font-weight-400 text-left">
                <p v-if="!$v.contactValues.surname.required">This is a required field</p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Email <span class="text-red">*</span></label>
              <b-form-input v-model="$v.contactValues.email.$model"
                @blur="$v.contactValues.email.$touch()"></b-form-input>
              <div v-if="$v.contactValues.email.$error" class="text-red font-weight-400 text-left">
                <p v-if="!$v.contactValues.email.email">Please enter a valid email</p>
                <p v-if="!$v.contactValues.email.required">This is a required field</p>
              </div>
            </b-col>
            <b-col cols="4">
              <label>Landline Number <span class="text-red">*</span></label>
              <b-form-input type="email" v-model="contactValues.landlineNumber"
                @blur="contactValues.landlineNumber.$touch()"></b-form-input>
              <!--                  <div v-if="$v.contactValues.landlineNumber.$error" class="text-red font-weight-400 text-left">-->
              <!--                    <p v-if="!$v.contactValues.landlineNumber.minLength || !$v.contactValues.landlineNumber.maxLength || !$v.contactValues.landlineNumber.numeric" >Please enter a valid number</p>-->
              <!--                    <p v-if="!$v.contactValues.landlineNumber.required" >This is a required field</p>-->
              <!--                  </div>-->
            </b-col>
            <b-col cols="4">
              <label>Mobile Number <span class="text-red">*</span></label>
              <b-form-input v-model="contactValues.cellNumber"
                @blur="$v.contactValues.cellNumber.$touch()"></b-form-input>
              <!--                  <div v-if="$v.contactValues.cellNumber.$error" class="text-red font-weight-400 text-left">-->
              <!--                    <p v-if="!$v.contactValues.cellNumber.minLength || !$v.contactValues.cellNumber.maxLength || !$v.contactValues.cellNumber.numeric" >Please enter a valid number</p>-->
              <!--                    <p v-if="!$v.contactValues.cellNumber.required" >This is a required field</p>-->
              <!--                  </div>-->
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Location <span class="text-red">*</span></label>
              <b-form-select v-model="contactValues.location">
                <b-form-select-option v-for="(item, index) in locations" :key="index" :value="item">{{
                  item.locationName
                }}</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="4" v-if="contactValues.location.locationId === 0">
              <label>Location Name<span class="text-red">*</span></label>
              <b-form-input v-model="contactValues.newLocation.locationName"></b-form-input>
              <p v-if="locationFilled === false">This is a required field</p>
            </b-col>
            <b-col cols="4" v-if="contactValues.location.locationId === 0">
              <label>Address<span class="text-red">*</span></label>
              <b-form-input v-model="contactValues.newLocation.address"></b-form-input>
              <p v-if="locationFilled === false">This is a required field</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" v-if="contactValues.location.locationId === 0">
              <label>City</label>
              <b-form-input v-model="contactValues.newLocation.city"></b-form-input>
            </b-col>
            <b-col cols="4" v-if="contactValues.location.locationId === 0">
              <label>Country</label>
              <b-form-input v-model="contactValues.newLocation.country"></b-form-input>
            </b-col>
            <b-col cols="4" v-if="contactValues.location.locationId === 0">
              <label>Postal Code</label>
              <b-form-input v-model="contactValues.newLocation.postalCode"></b-form-input>
            </b-col>
          </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col>
              <div class="d-flex justify-content-end">
                <div>
                  <b-button variant="red" squared @click="addNewContactToggle" class="ml-2">Cancel</b-button>
                </div>
                <div>
                  <b-button variant="primary" squared @click="locationCreate" class="ml-2"
                    :disabled="$v.contactValues.$invalid">Save</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div v-if="!showClientContactAdd">
        <b-row v-if="!showClientContactAdd">
          <b-col>
            <b-table striped hover :items="clientDataTable.dataSource" :fields="clientDataTable.tableColumns"
              @row-clicked="addClient" :total-rows="clientDataTableRows" :current-page="clientDataTable.currentPage"
              :per-page="clientDataTable.resultsPerPage" sort-icon-left>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
                </div>
              </template>

              <template #cell(actions)="row">
                <b-row align-v="center" align-h="end">
                  <b-button @click="addClient(row.item)" size="sm" class="btn-icon">
                    <b-icon-chevron-right></b-icon-chevron-right>
                  </b-button>
                </b-row>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row align-h="center" v-if="!showClientContactAdd">
          <b-pagination v-model="clientDataTable.currentPage" :total-rows="clientDataTableRows"
            :per-page="clientDataTable.resultsPerPage" aria-controls="clientModalTable"></b-pagination>
        </b-row>
        <b-row class="mt-4">
          <b-col v-if="!showClientContactAdd">
            <b-button hidden variant="primary" @click="addNewContactToggle"> Add New Contact </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button variant="red" class="" squared @click="closeClientSearchModal">Cancel</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="search-technician-modal" size="xl" hide-footer no-header @close="closeTechnicianSearchModal">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="technicianSearchField.name"></b-form-input>
        </b-col>
        <b-col cols="4">
          <label>Surname</label>
          <b-form-input v-model="technicianSearchField.surname"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2" @click="clearTechnicianSearchField">Clear</b-button>
          <b-button variant="primary" squared @click="filterTechs">Search</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table striped hover :items="technicianDataTable.dataSource" :fields="technicianDataTable.tableColumns"
          :busy="technicianDataTable.isLoading" @row-clicked="addTechnician"
          :per-page="technicianDataTable.resultsPerPage" id="technicianModalTable"
          :current-page="technicianDataTable.currentPage" sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button @click="addTechnician(row.item)" size="sm" class="btn-icon">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>
        </b-table>
      </b-row>
      <b-row align-h="center">
        <b-pagination v-model="technicianDataTable.currentPage" :total-rows="technicianDataTableRows"
          :per-page="technicianDataTable.resultsPerPage" aria-controls="technicianModalTable"></b-pagination>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button variant="red" class="mr-2" squared @click="closeTechnicianSearchModal">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="survey-modal" hide-footer no-header no-close title="Select Surveys to send to client">
      <b-row>
        <b-col>
          <b-table striped hover selectable responsive="sm" selected-variant="primary" :select-mode='selectMode'
            :items="surveyDataTable.dataSource" :fields="['description']" :busy="surveyDataTable.isLoading"
            :per-page="surveyDataTable.resultsPerPage" id="surveyModalTable" ref="selectableTable"
            :current-page="surveyDataTable.currentPage" @row-selected="onSurveyRowSelected" sort-icon-left>
            <!-- <template #cell(selectedSurveys)="{ rowSelected  }">
            <template v-if="rowSelected">
              <span aria-hidden="false">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="false">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template> -->

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <!-- <b-col>
          <b-form-checkbox v-model="selectedJobCard.surveySent" unchecked-value="false">
              Never show this again
          </b-form-checkbox> 
      </b-col> -->
        <b-col class="text-right">
          <b-button size="sm" variant="primary" squared @click="closeSurveyModal()">Done</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal class="p-0" id="MessageBox" hide-footer title="Job Card" @close="closeMessageBox">
      <b-row>
        <b-col class="text-center">
          <p>
            {{ this.messageBoxMessage }}
          </p>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col class="text-center">
          <b-button variant="primary" squared class="ml-2" @click="closeMessageBox">OK</b-button>
        </b-col>
      </b-row>
    </b-modal>


  </div>
</template>
<script src="https://cdn-dlbkf.nitrocdn.com/HGtSbkfuJkdZEDpUDndvlKOopygrXPtY/assets/static/optimized/rev-8e641ab/wp-content/plugins/woocommerce/assets/js/jquery-blockui/nitro-min-bd431cb1a69a14e57743c5eec7ad0cfc.jquery.blockUI.min.js" data-nitro-for-id="jquery-blockui-js" type="text/javascript" id="jquery-blockui-js"></script>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import jobcardDetails from '@/components/jobCards/Details.vue';
import stock from '@/components/jobCards/Stock.vue';
import checklist from '@/components/jobCards/Checklist.vue';
import labour from '@/components/jobCards/Labour.vue';
import documents from '@/components/jobCards/Documents.vue';
import quote from '@/components/jobCards/Quote.vue';
import survey from '@/components/jobCards/Survey.vue';
import EquipmentAndConsumables from '@/components/jobCards/EquipmentAndConsumables.vue';
import AllocatedMaterial from '@/components/jobCards/AllocatedMaterial';
import AssociatedCost from '@/components/jobCards/AssociatedCost';
import { email, maxLength, minLength, numeric, required } from 'vuelidate/lib/validators';

export default {
  components: {
    jobcardDetails,
    stock,
    checklist,
    labour,
    documents,
    quote,
    survey,
    EquipmentAndConsumables,
    AllocatedMaterial,
    AssociatedCost,
  },
  data: () => ({
    surveyForced: false,
    messageBoxMessage:'',
    selectMode: 'single',
    selectedSurveys: [],
    state: 'initializing',
    userFullname: null,
    clientContactNumber: null,
    clientContactNumberAlt: null,
    clientEmail: null,
    clientAccountNumber: null,
    isStatusClosed: false,
    jobCard: {
      accountNumber: null,
      subject: null,
      details: null,
      experiencingUser: null,
      referenceNumber: null,
      client: null,
      company: null,
      technicianGroup: null,
      technicianGroupId: null,
      technicianId: null,
      technician: null,
      statusId: null,
      dueDate: null,
      createdDate: null,
      dateOpened: null,
      priorityID: null,
      clientReference: null,
      purchaseOrderNumber: null,
      contractType: null,
      budgetaryQuotes: [],
      priority: null,
    },
    computed: {
      console: () => console,
      window: () => window,
    },
    locationsForUser: [],
    contactSearchField: {
      name: null,
    },
    surveyDataTable: {
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Survey',
          key: 'description',
          sortable: true,
          tdClass: '',
        }
      ],
    },
    companySearchField: {
      name: null,
    },
    companyDataTable: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
    tableClientCompanyData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Contact Number',
          key: 'phone',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
    clientDataTable: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Contact Number',
          key: 'phone',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: true,
          tdClass: '',
        },
      ],
    },
    technicianDataTable: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Contact Number',
          key: 'contactNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: true,
          tdClass: '',
        },
      ],
    },
    technicianSearchField: {
      name: null,
      surname: null
    },

    technicians: [],
    status: [],
    priority: [],
    selectedPriority: [],
    slaTime: null,
    contactDetails: [],
    tabIndex: 0,
    showClientContactAdd: false,
    locationFilled: null,
    contactValues: {
      name: null,
      surname: null,
      email: null,
      cellNumber: null,
      companyId: null,
      company: { description: null },
      landlineNumber: null,
      location: { locationId: null },
      newLocation: { locationName: null, address: null, city: null, country: null, postalCode: null },
    },
    ogTechList: [],
    locationValues: {
      companyID: null,
      locationName: null,
      address: null,
      city: null,
      country: null,
      postalCode: null,
    },
    search: {
      name: null,
    },
    request: {
      company: { companyName: null },
    },
    locations: [],
  }),
  created() {
    this.getSelectedJobCard();
    this.setBreadcrumb([
      {
        text: 'Job card',
      },
      {
        text: 'Details',
      },
    ]);
    this.searchPriority().then((response) => {
      this.priority = response.data;
    });
    // this.locationSearch()
    // this.companySearch()
  },
  methods: {
    ...mapActions([
      'getJobCard',
      'searchCompanies',
      'getAllSurveys',
      'addSurveysToTicket',
      'searchClientByCompany',
      'searchContactsRequest',
      'updateJobCard',
      'searchTechnician',
      'searchTechnicians',
      'searchStatus',
      'searchPriority',
      'searchLocationByCompany',
    ]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    canSendSurvey(){
      let result = this.$root.urlConfig.send_survey
      return result;
    },

    getSelectedJobCard() {
      if (this.$store.state.selectedJobCard === null) {
        this.goBackToSearch();
      }
      
      this.getJobCard().then(() => {
        this.statusSearch();

        if (this.selectedJobCard.dueDate != null)
         {this.selectedJobCard.dueDate = this.selectedJobCard.dueDate.toString().replace('T' , ' ')}
        this.clientAccountNumber = this.selectedJobCard.clientAccountNumber
        this.clientContactNumber = this.selectedJobCard.clientContactNumber
        this.clientContactNumberAlt = this.selectedJobCard.clientContactNumberAlt
        this.clientEmail = this.selectedJobCard.clientEmail
        if (this.selectedJobCard.client !== null && this.selectedJobCard.client !== '') {
            let request = {
                companyId: this.selectedJobCard.companyId
            }
            this.$store.commit('setSearchLocationByCompanyRequest', request)
            this.searchLocationByCompany()
            .then((res) => {
              if (res==null)
                this.locationsForUser=null;
              else
                this.locationsForUser = res.data
                this.state = 'show';
            })
        } else {
          this.state = 'show';
        }
        // }
      });
      this.determineSlaTime();
    },

    closeMessageBox() {
      this.$bvModal.hide("MessageBox");
    },
    showMessageBox(amessage) {
      this.messageBoxMessage = amessage
      this.$bvModal.show("MessageBox");
    },
    
    goBackToSearch() {
      this.$router.push({ path: '/JobCards/search' });
    },

    updateJobCardDetails() 
    {
      //console.log("saving");
      this.state = 'loading';

      //if resolved
      if (this.selectedJobCard.statusId === 5) {
        this.selectedJobCard.jobCompleted = true;
      }

      if (this.secondaryRequestTypes.length > 0) {
      this.selectedJobCard.ticketRequestTypeId = this.secondaryRequestTypes[this.secondaryRequestTypes.length-1].value
      }

      this.updateJobCard()
        .then(() => {
         
          if (this.surveyForced == true)
          {
            this.goBackToSearch();
          }
          else
          {        
            this.getSelectedJobCard();
          }
          this.state = 'show';
        })
        .catch(error => {
          alert("An error occured during save:"+ error.message)
          this.state = 'show';
        });
    },
    determineSlaTime() {
      // let due = Date.parse(this.selectedJobCard.dueDate) ? Date.parse(this.selectedJobCard.dueDate) : 0;
      // let created = Date.parse(this.selectedJobCard.createdDate);
      // let today = new Date().getTime();

      // let available = Math.round(due - created);
      // let left = Math.round(due - today);

      // let time = (left / available) * 100;

      // if (time > 100) {
      //   this.slaTime = 100;
      // } else if (time < 0) {
      //   this.slaTime = 0;
      // } else {
      //   this.slaTime = time.toFixed();
      // }
      let due = this.selectedJobCard.dueDate===null ? 0 : Date.parse(this.selectedJobCard.dueDate) ? Date.parse(this.selectedJobCard.dueDate) : 0;
      let created = Date.parse(this.selectedJobCard.createdDate);
      let today = new Date().getTime();

      let x = Math.round(due - created);
      // let left = Math.round(due - today);
      let elapsed = Math.round(today -created)
      let time = (elapsed / x) * 100;

      if (time > 100) {
        this.slaTime = 100;
      } else if (time < 0) {
        this.slaTime = 0;
      } else {
        this.slaTime = time.toFixed();
      }
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-white'];
      } else {
        return ['text-black-50'];
      }
    },
    addNewContactToggle() {
      this.showClientContactAdd = !this.showClientContactAdd;
    },
    checkContactValues(invalidCheck) {
      let locationCheckPassed = true;
      if (this.contactValues.location.locationId === null) {
        locationCheckPassed = false;
      }
      if (this.contactValues.location.locationId === 0) {
        if (
          this.contactValues.newLocation.locationName === null ||
          this.contactValues.newLocation.locationName === ''
        ) {
          locationCheckPassed = false;
        }
        if (this.contactValues.newLocation.address === null || this.contactValues.newLocation.address === '') {
          locationCheckPassed = false;
        }
      }
      return !(invalidCheck === false && locationCheckPassed === true);
    },

    onSurveyRowSelected(items)
    {
       this.selectedSurveys = items;
    },

    openSurveyModal(forceSurvey) {
      // console.log("forceSurvey", forceSurvey)
      // console.log("isSignedOff", this.selectedJobCard.isSignedOff)
      // console.log("statusId", this.selectedJobCard.statusId)
      // console.log("this.selectedJobCard.selectedSurveys.length", this.selectedJobCard.selectedSurveys.length)
      // console.log("this.selectedJobCard.surveySent", this.selectedJobCard.surveySent)
      // console.log("this.canSendSurvey()", this.canSendSurvey())


      if (this.canSendSurvey() == true && (this.selectedJobCard.surveySent == null || this.selectedJobCard.surveySent == false)
        && (this.selectedJobCard.isSignedOff == true || this.selectedJobCard.statusId == 5)
        && this.selectedJobCard.selectedSurveys.length == 0)
      {
        this.getSurveyList();
        this.$bvModal.show('survey-modal');
      }
      else if (forceSurvey==false)
      {
         this.updateJobCardDetails()

         
      }
    },

    closeSurveyModal() {
      //console.log("selected Surveys ",this.selectedSurveys)
      //check if any surveys are selected
      if (this.selectedSurveys.length>0){
        //add the surveys to the ticket
        this.addSurveys()
        //close the dialog
        this.$bvModal.hide('survey-modal');
      }
      else{
        //no survey selected
        //save the ticket
        this.showMessageBox("You need to select at least one survey in order to continue")
      }
    },
    
    getSurveyList() {
      this.surveyDataTable.isLoading = true;
      this.getAllSurveys()
        .then((response) => {
          console.log("this.surveyDataTable.dataSource",this.surveyDataTable.dataSource)
          this.surveyDataTable.dataSource = response.data;
          this.surveyDataTable.isLoading = false;
        })
        .catch(() => {
          this.surveyDataTable.isLoading = false;
        });
    },

    addSurveys(){
      let request = this.selectedSurveys.map(item => item.surveyID);
      this.$store.commit('setAddServeysRequest', request);
      this.addSurveysToTicket().then(() =>{
        //save the ticket 
        this.selectedJobCard.surveySent = true
        this.updateJobCardDetails()
      }
      )
      .catch(()=>{
        //console.log("Some issue")
        //do something or nothing
      })
    },

    openCompanySearchModal() {
      this.$bvModal.show('search-company-modal');
      this.companySearch();
    },
    closeCompanySearchModal() {
      this.$bvModal.hide('search-company-modal');
    },
    companySearch() {
      this.companyDataTable.isLoading = true;
      let request = [];

      //this.closeCompanySearchModal();
      if (this.companySearchField.name != null) {
        request.push({ key: 'companyName', value: this.companySearchField.name });
      }

      this.$store.commit('setSearchCompaniesRequest', request);
      this.searchCompanies()
        .then((response) => {
          this.companyDataTable.dataSource = response.data;
          this.companyDataTable.isLoading = false;
        })
        .catch(() => {
          this.companyDataTable.isLoading = false;
        });
    },
    addCompany(rowItem) {
      this.selectedJobCard.company = rowItem.description;
      this.selectedJobCard.companyID = rowItem.id;
      this.selectedJobCard.client = null;
      this.selectedJobCard.location = null;
      this.closeCompanySearchModal();
    },
    locationSearch() {
      const companyID = this.contactDetails.companyId;
      this.$store.commit('setSearchLocationByCompanyRequest', companyID);
      //TODO: get company ID add to the object
      this.searchLocationByCompany().then((response) => {
        this.locations = response.data;
        this.locations.unshift({ locationId: 0, locationName: 'Add new location' });
      });
    },
    locationCreate() {
      if (this.contactValues.location.locationId === 0) {
        this.locationValues.companyID = this.contactValues.companyId;
        this.locationValues.locationName = this.contactValues.newLocation.locationName;
        this.locationValues.address = this.contactValues.newLocation.address;
        this.$store.commit('setLocationCreateRequest', this.locationValues);
        this.registerNewLocation()
          .then((res) => {
            this.contactValues.location = {
              locationId: res.data.result.locationId,
              locationName: this.contactValues.newLocation.locationName,
              companyID: this.contactValues.companyId,
            };
            this.addNewContact();
          })
          .catch(() => {});
      } else {
        this.addNewContact();
      }
    },
    clearCompanySearchField() {
      this.companySearchField = {
        name: null,
      };
      this.companySearch();
    },
    addNewContact() {
      let request = {
        name: this.contactValues.name,
        surname: this.contactValues.surname,
        email: this.contactValues.email,
        cellNumber: this.contactValues.cellNumber,
        companyID: this.contactValues.companyId,
        locationId: this.contactValues.location.locationId,
        location: this.contactValues.newLocation.locationName,
        phone: this.contactValues.landlineNumber,
      };
      this.$store.commit('setContactCreateRequest', request);
      this.state = 'loading';
      this.createContact()
        .then(() => {
          this.goBackToSearch();
        })
        .catch(() => {
          this.state = 'show';
        });
    },

    openClientSearchModal() {
      this.$root.$emit('bv::show::modal', 'search-client-modal', '#btnShow');

      this.contactValues = {
        name: null,
        surname: null,
        email: null,
        cellNumber: null,
        company: this.request.company,
        location: { locationId: null, locationName: null },
        newLocation: { locationName: null, address: null, city: null, country: null, postalCode: null },
        landlineNumber: null,
      };

      this.doSearchByCompany();
    },
    closeClientSearchModal() {
      this.$bvModal.hide('search-client-modal');
    },
    doSearchClientList() {
      this.tableClientCompanyData.isLoading = true;

      let searchName = this.clientSearch.name !== null ? this.clientSearch.name.toLowerCase() : null;
      let searchSurname = this.clientSearch.surname !== null ? this.clientSearch.surname.toLowerCase() : null;
      let newArray = this.ogClientList.filter(function (el) {
        return (
          (el.name !== null ? el.name.toLowerCase().includes(searchName) : false) ||
          (el.surname !== null ? el.surname.toLowerCase().includes(searchSurname) : false)
        );
      });

      this.tableClientCompanyData.dataSource = newArray;
      this.tableClientCompanyData.isLoading = false;
    },
    doSearchByCompany() {
      this.clientDataTable.isLoading = true;

      let request = {
        companyID: this.selectedJobCard.companyID,
      };
      //console.log(request)
      //console.log(this.selectedJobCard);
      //console.log('sdlkasdkajsd');
      this.$store.commit('setSearchClientByCompanyRequest', request);

      this.searchClientByCompany()
        .then((res) => {
          this.ogClientList = res.data;
          this.clientDataTable.dataSource = res.data;
          this.clientDataTable.isLoading = false;
        })
        .catch((error) => {
          this.clientDataTable.isLoading = false;
          console.log("Error",error);
        });
    },
    clientSearch() {
      this.clientDataTable.isLoading = true;

      let request = [];

      if (this.contactSearchField.name !== null) {
        request.push({ key: 'name', value: this.contactSearchField.name });
      }

      this.$store.commit('setContactSearchRequest', request);

      this.searchContactsRequest()
        .then((request) => {
          this.clientDataTable.dataSource = request.data.filter(
            (item) => item.company === this.selectedJobCard.company
          );
          this.contactDetails = this.clientDataTable.dataSource.filter(
            (item) => item.clientContactId === this.selectedJobCard.clientId
          );
          this.tableData.isLoading = false;
        })
        .catch(() => {
          this.clientDataTable.isLoading = false;
        });
    },
    addClient(rowItem) {
      this.selectedJobCard.client = rowItem.name + ' ' + rowItem.surname;
      this.selectedJobCard.experiencingUser = rowItem.name + ' ' + rowItem.surname;
      this.selectedJobCard.clientId = rowItem.clientContactId;
      this.selectedJobCard.companyID = rowItem.companyID;
      this.selectedJobCard.location = rowItem.location;
      this.closeClientSearchModal();
      let request = {
        companyId: this.selectedJobCard.companyID,
      };
      //console.log('request.company.id ' + this.selectedJobCard.companyID);
      this.$store.commit('setSearchLocationByCompanyRequest', request);
      this.searchLocationByCompany().then((res) => {
        this.locationsForUser = res.data;
        //console.log('locations', this.locationsForUser);
      });
    },
    clearContactSearchField() {
      this.contactSearchField = {
        name: null,
      };
      this.clientSearch();
    },

    openTechnicianModal() {
      this.$bvModal.show('search-technician-modal');
      this.dosearchTechnician();
    },
    closeTechnicianSearchModal() {
      this.$bvModal.hide('search-technician-modal');
    },
    // openTechnicianGroupModal() {
    //   this.$bvModal.show('search-technician-modal')
    // },
    // closeTechnicianSearchModal() {
    //   this.$bvModal.hide('search-technician-modal')
    // },
    filterTechs() {
      this.technicianDataTable.isLoading = true;

      let searchName = this.technicianSearchField.name !== null ? this.technicianSearchField.name.toLowerCase() : null;
      let searchSurname = this.technicianSearchField.surname !== null ? this.technicianSearchField.surname.toLowerCase() : null;
      let newArray = this.ogTechList.filter(function (el) {
          return (
          (el.name !== null ? el.name.toLowerCase().includes(searchName) : false) ||
          (el.surname !== null ? el.surname.toLowerCase().includes(searchSurname) : false)
          );
      });

      this.technicianDataTable.dataSource = newArray;
      this.technicianDataTable.isLoading = false;
    },
    dosearchTechnician() {
      let request = [];
      if (this.technicianSearchField.name !== null) {
        request.push({ key: 'name', value: this.technicianSearchField.name });
      }
      this.$store.commit('setTechnicianSearchRequest', request);
      this.searchTechnician().then((response) => {
        this.state = 'show';
        this.ogTechList = response.data
        this.technicianDataTable.dataSource = response.data;
        this.technicianDataTable.isLoading = false;
      });
    },
    addTechnician(rowItem) {
      //console.log('tech row item', rowItem);
      this.selectedJobCard.technician = rowItem.name + ' ' + rowItem.surname;
      this.selectedJobCard.technicianId = rowItem.userId;
      this.closeTechnicianSearchModal();
    },
    clearTechnicianSearchField() {
      this.technicianSearchField = {
        name: null,
        surname: null
      };
      
      this.technicianDataTable.isLoading = true;
      
      this.technicianDataTable.dataSource = this.ogTechList;
      this.technicianDataTable.isLoading = false;
    },
    statusSearch() {
      this.searchStatus().then((response) => {
        this.status = response.data;
        if (this.selectedJobCard.statusId == 3)
        {
          this.status.push({"id":3,"description":"Closed","whdid":3})
        }
console.log('this.status',this.status);

          const status = this.status.find((x) => x.description === "Closed");
          if (status && this.selectedJobCard.statusId === status.id) {
            this.isStatusClosed = true;
          }
      });
    },
    formatDatetime(value) {
    //return datetime ? datetime.slice(0, -3) : ''; // Remove the last three characters representing seconds

    if (!value) return ''
  let localeValue = new Date(value)
  let response = localeValue.getDate().toString().padStart(2, '0')+ '/' + (localeValue.getMonth()+1).toString().padStart(2,'0') + '/' + localeValue.getFullYear() + ' ' + localeValue.getHours().toString().padStart(2, '0') + ':' + localeValue.getMinutes().toString().padStart(2, '0')
  return response

  }
  },
  computed: {
    ...mapState(['selectedJobCard', 'secondaryRequestTypes']),
    companyDataTableRows() {
      return this.companyDataTable.dataSource.length;
    },
    clientDataTableRows() {
      return this.clientDataTable.dataSource.length;
    },
    technicianDataTableRows() {
      return this.technicianDataTable.dataSource.length;
    },
    checkColourForVariant() {
      if (this.slaTime < 50) {
        return 'green';
      } else if (this.slaTime < 80) {
        return 'orange';
      } else {
        return 'red';
      }
    },
  },

  validations: {
    contactValues: {
      name: { required },
      surname: { required },
      location: { required },
      landlineNumber: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      cellNumber: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      email: { required, email },
    },
  },

  mounted() {
    const thisInstance = this
    this.$root.$on('openSurveyModalEvent', function(forceSurvey){
      this.surveyForced = forceSurvey
      thisInstance.openSurveyModal(forceSurvey)
    })    
  }
};
</script>
