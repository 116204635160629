<template>
    <div>
        <b-row>
            <b-col cols="8">
                <label>Request Details</label>
                <b-form-textarea
                    class="pt-2"
                    rows="2"
                    size="sm"
                    v-model="selectedJobCard.details"
                    :disabled="isStatusClosed"
                ></b-form-textarea>
            </b-col>
            <b-col cols="4">
                <label>Ticket Type</label>
                <b-form-input
                    v-model="selectedJobCard.ticketType"
                    :disabled="true"
                ></b-form-input>
            </b-col>
            
        </b-row>
        <b-row>
            <!-- <b-col cols="4">
                <label>Request Type</label>
                <b-form-select v-model="selectedJobCard.ticketRequestTypeId">
                    <b-form-select-option v-for="(item, index) in requestTypes" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                </b-form-select>
            </b-col> -->
            <b-col
                cols="4"
                v-for="(listItem, index) in secondaryRequestTypes"
                :key="index">
              
                <label
                    >Request
                    {{ index > 0 ? "Sub Type " + index : "Type" }}</label
                >

                <b-form-select disabled="true">
                    <b-form-select-option>
                        {{ listItem.description }}
                    </b-form-select-option>

                </b-form-select>

                <!-- <b-form-select
                    v-model="listItem.value"
                    @change="loadNextRequestType(listItem, index)"
                    :disabled="true"
                >
                    <b-form-select-option
                        v-for="(item, index) in listItem.list"
                        :key="index"
                        :value="item.id"
                        >{{ item.description }}</b-form-select-option
                    >
                </b-form-select> -->
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <label>Room</label>
                <b-form-input v-model="selectedJobCard.room" :disabled="isStatusClosed"></b-form-input>
            </b-col>
            <b-col cols="4">
                <label>Client Signoff</label>
                <b-row class="">
                    <b-col cols="12" class="p-0">
                        <b-button
                            block
                            variant="primary"
                            @click="openClientSignOffModal" :disabled="isStatusClosed"
                            >View</b-button
                        >
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
                <label>Service Record Summary</label>
                <b-row class="">
                    <b-col cols="12" class="p-0">
                        <b-button
                            block
                            variant="primary"
                            @click="downloadSummary"
                            >
                            <b-spinner
                                    small
                                    v-if="this.otpstate === 'downloading'"
                            ></b-spinner>
                            Download</b-button
                        >
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
             <b-col cols="4">
                <label></label>
                <b-button
                    variant="primary"
                    squared
                    class="ml-2"
                    @click="openNoteModal"
                    :disabled="isStatusClosed"
                    >Add Note</b-button
                >
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <h6 class="mt-3">Notes</h6>
                <b-table
                    striped
                    hover
                    :items="selectedJobCard.notes"
                    :fields="notesTableData.tableColumns"
                    :busy="notesTableData.isLoading"
                    :per-page="notesTableData.resultsPerPage"
                    id="noteTable"
                    :current-page="notesTableData.currentPage"
                    sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner
                                style="width: 3rem; height: 3rem"
                            ></b-spinner>
                        </div>
                    </template>

                    <template #cell(noteDate)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{
                                row.item.noteDate | dateTimeFilter
                            }}</span>
                        </b-row>
                    </template>

                    <!-- <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">
                            <b-button
                                @click="showRemoveNoteModal(row.item)"
                                variant="red"
                                size="sm"
                            >
                                Delete
                            </b-button>
                        </b-row>
                    </template> -->
                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center">
            <b-pagination
                v-model="notesTableData.currentPage"
                :total-rows="noteTableRows"
                :per-page="notesTableData.resultsPerPage"
                aria-controls="jobCardTable"
            ></b-pagination>
        </b-row>

        <b-modal
            class="p-0"
            id="clientSignOffModal"
            hide-footer
            size="lg"
            title="Client Sign Off"
            @close="closeClientSignOffModal"
        >
            <b-row v-if="otpMessage !== null">
                <b-col>
                    <b-alert :show="true" dismissible :variant="otpColour">{{
                        otpMessage
                    }}</b-alert>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <label>{{ formData.signOffType.label }}</label>
                    <b-form-select v-model="formData.signOffType.value">
                        <b-form-select-option value="0"
                            >Partial Signoff</b-form-select-option
                        >
                        <b-form-select-option value="1"
                            >Full Signoff
                        </b-form-select-option>
                        <!-- <b-form-select-option value="2">Enter OTP      </b-form-select-option> -->
                    </b-form-select>
                </b-col>
            </b-row>

            <hr class="mx-3" />
            <b-row>
                <b-col>
                    <label
                        >{{ formData.signOffWorkType.label }}
                        <span v-if="isRequired" class="text-red">*</span></label
                    >
                    <b-form-select
                        v-model="formData.signOffWorkType.value"
                        @change="validateModalForm"
                    >
                        <b-form-select-option
                            v-for="(item, index) in workTypes"
                            :key="index"
                            :value="item.workTypeId"
                            >{{ item.description }}</b-form-select-option
                        >
                    </b-form-select>
                    <span v-if="formData.signOffWorkType.error" class="text-red font-weight-400 text-left">{{ formData.signOffWorkType.error }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label
                        >{{ formData.date.label }}
                        <span v-if="isRequired" class="text-red">*</span></label
                    >
                    <b-form-datepicker
                        v-model="formData.date.value"
                        @input="validateModalForm"
                    ></b-form-datepicker>
                    <span v-if="formData.date.error" class="text-red font-weight-400 text-left">{{ formData.date.error }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button
                        variant="no-back"
                        class="link-btn mr-2"
                        size="sm"
                        @click="
                            isBulkTime = true;
                            formData.fromDate.value = null;
                            formData.toDate.value = null;
                            removeRequiredField('fromDate');
                            removeRequiredField('toDate');
                            addRequiredField('hours');
                        "
                        >Add Bulk Time</b-button
                    >
                    <b-button
                        variant="no-back"
                        class="link-btn"
                        size="sm"
                        @click="
                            isBulkTime = false;
                            formData.hours.value = 0;
                            addRequiredField('fromDate');
                            addRequiredField('toDate');
                            removeRequiredField('hours');
                        "
                        >Add Specific Time</b-button
                    >
                </b-col>
            </b-row>

            <div v-if="isBulkTime">
                <b-row>
                    <b-col>
                        <label
                            > {{ formData.hours.label }}
                            <span v-if="isRequired" class="text-red"
                                >*</span
                            ></label
                        >
                        <b-form-input
                            v-model="formData.hours.value"
                            type="number"
                            @input="validateModalForm"
                        ></b-form-input>
                        <span v-if="formData.hours.error" class="text-red font-weight-400 text-left">{{ formData.hours.error }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button
                            variant="no-back"
                            class="link-btn ml-2"
                            size="sm"
                            @click="addTimeToBulkValue(0.25)"
                            >+0:15</b-button
                        >
                        <b-button
                            variant="no-back"
                            class="link-btn ml-2"
                            size="sm"
                            @click="addTimeToBulkValue(0.5)"
                            >+0:30</b-button
                        >
                        <b-button
                            variant="no-back"
                            class="link-btn ml-2"
                            size="sm"
                            @click="addTimeToBulkValue(1)"
                            >+1:00</b-button
                        >
                        <b-button
                            variant="no-back"
                            class="link-btn ml-2"
                            size="sm"
                            @click="addTimeToBulkValue(2)"
                            >+2:00</b-button
                        >
                        <b-button
                            variant="no-back"
                            class="link-btn ml-2"
                            size="sm"
                            @click="addTimeToBulkValue(4)"
                            >+4:00</b-button
                        >
                        <b-button
                            variant="no-back"
                            class="link-btn ml-2"
                            size="sm"
                            @click="addTimeToBulkValue(8)"
                            >+8:00</b-button
                        >
                    </b-col>
                </b-row>
            </div>
            <div v-if="!isBulkTime">
                <b-row>
                    <b-col>
                        <label
                            >{{ formData.fromDate.label }}
                            <span v-if="isRequired" class="text-red"
                                >*</span
                            ></label
                        >
                        <b-form-timepicker
                            v-model="formData.fromDate.value"
                            locale="en"
                            @input="validateDate"
                        ></b-form-timepicker>
                        <span v-if="formData.fromDate.error" class="text-red font-weight-400 text-left">{{ formData.fromDate.error }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label
                            > {{ formData.toDate.label }}
                            <span v-if="isRequired" class="text-red"
                                >*</span
                            ></label
                        >
                        <b-form-timepicker
                            v-model="formData.toDate.value"
                            locale="en"
                            @input="validateDate"
                        ></b-form-timepicker>
                        <span v-if="formData.toDate.error" class="text-red font-weight-400 text-left">{{ formData.toDate.error }}</span>
                    </b-col>
                </b-row>
            </div>

            <b-row>
                <b-col>
                    <label
                        > {{ formData.signOffComments.label }}
                        <span v-if="isRequired" class="text-red">*</span></label
                    >
                    <b-form-textarea
                        v-model="formData.signOffComments.value"
                        @input="validateModalForm"
                    ></b-form-textarea>
                    <span v-if="formData.signOffComments.error" class="text-red font-weight-400 text-left">{{ formData.signOffComments.error }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p><strong>Email Address</strong></p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-input-group>
                        <b-form-input v-model="formData.otpEmail.value"></b-form-input>
                        <b-input-group-append>
                            <b-button
                                size="sm"
                                variant="secondary"
                                squared
                                @click="sendOtpForm()"
                                class="ml-2"
                            >
                                <b-spinner
                                    small
                                    v-if="this.otpstate === 'sending'"
                                ></b-spinner>
                                &nbsp;&nbsp;Send OTP&nbsp;</b-button
                            >
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row id="optEmailenter">
                <b-col>
                    <label>{{ formData.otp.label }}</label>
                    <b-input-group>
                        <b-form-input v-model="formData.otp.value"></b-form-input>
                        <b-input-group-append>
                            <b-button
                                size="sm"
                                text="Button"
                                variant="secondary"
                                squared
                                class="ml-2"
                                @click="checkOtp()"
                            >
                                <b-spinner
                                    small
                                    v-if="this.otpstate === 'sending'"
                                ></b-spinner>
                                &nbsp;Check OTP</b-button
                            >
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <hr class="mx-3" />
            <b-row>
                <b-col class="text-right">
                    <b-button
                        variant="red"
                        squared
                        class="ml-2"
                        @click="closeClientSignOffModal"
                        >Cancel</b-button
                    >
                </b-col>
            </b-row>
        </b-modal>

        <b-modal
            class="p-0"
            id="clientCompletedSignOffModal"
            hide-footer
            size="lg"
            title="Client Sign Off"
            @close="closeClientCompletedSignOffModal"
        >
            <b-row>
                <b-col>
                    <p>
                        Client successfully signed off using OTP on the
                        {{ selectedJobCard.singnOffDate | dateTimeFilter }}
                    </p>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col class="text-right">
                    <b-button
                        variant="primary"
                        squared
                        class="ml-2"
                        @click="closeClientCompletedSignOffModal"
                        >Done</b-button
                    >
                </b-col>
            </b-row>
        </b-modal>

        <b-modal
            class="p-0"
            id="addNoteModal"
            size="lg"
            hide-footer
            title="Add Note"
            @close="closeNoteModal"
        >
            <b-row>
                <b-col cols="5">
                    <label>Header</label>
                    <!-- <b-form-input
                        v-model="noteDetails.noteHeader"
                    ></b-form-input> -->
                     <b-form-select v-model="noteDetails.noteHeader">
                        <b-form-select-option v-for="(item, index) in workTypes" :key="index" :value="item.description">{{item.description}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>Note</label>
                    <b-form-textarea
                        v-model="noteDetails.note"
                    ></b-form-textarea>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-end">
                        <div>
                            <b-button
                                variant="red"
                                squared
                                class="ml-2"
                                @click="closeNoteModal"
                                >Cancel</b-button
                            >
                        </div>
                        <div>
                            <b-button
                                variant="primary"
                                squared
                                class="ml-2"
                                @click="createNewNote"
                                >Save</b-button
                            >
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal
            class="p-0"
            id="removeNoteModal"
            size="md"
            hide-footer
            title="Remove Note"
            @close="hideRemoveNoteModal"
        >
            <b-row>
                <b-col cols="12">
                    <label
                        >Are you sure you wish to delete
                        <span
                            v-if="this.selectedNote !== null"
                            class="text-red font-weight-bold"
                            >{{ this.selectedNote.noteHeader }}</span
                        >
                        note?</label
                    >
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-end">
                        <div>
                            <b-button
                                variant="red"
                                squared
                                class="ml-2"
                                @click="removeNote"
                                >Remove</b-button
                            >
                        </div>
                        <div>
                            <b-button
                                variant="red"
                                squared
                                class="ml-2"
                                @click="hideRemoveNoteModal"
                                >Cancel</b-button
                            >
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    props: {
        isStatusClosed: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        isBulkTime: false,
        otpstate: "idle",
        workTypes: null,
        state: "show",
        //signOffDate:null,
        //signOffHours:null,
        isRequired: false,
        requiredFields: ["signOffWorkType", "signOffComments", "otpEmail", "date", "fromDate", "toDate"],
        formData: {
            signOffType: {
                label: 'Signoff Type',
                value: "0",
                error: "",
            },
            signOffWorkType: {
                label: 'Type of work',
                value: null,
                error: "",
            },
            signOffComments: {
                label: 'Notes',
                value: null,
                error: "",
            },
            otp: {
                label: 'Enter OTP to sign off',
                value: null,
                error: "",
            },
            otpEmail: {
              label: 'Email',
                value: null,
                error: "",
            },
            date: {
                label: 'Date',
                value: null,
                error: "",
            },
            fromDate: {
                label: 'From Time',
                value: null,
                error: "",
            },
            toDate: {
                label: 'To Time',
                value: null,
                error: "",
            },
            hours: {
                label: 'Hours Worked',
                value: 0,
                error: "",
            },
        },
        jobCardDetails: {
            details: null,
            ticketType: null,
            ticketId: null,
        },
        otpMessage: null,
        otpColour: "success",
        noteDetails: {
            note: null,
            noteHeader: null,
            ticketId: 0,
            noteDate: null,
        },
        notesTableData: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: "Date",
                    key: "noteDate",
                    sortable: true,
                    tdClass: "",
                },
                {
                    label: "Name",
                    key: "user",
                    sortable: true,
                    tdClass: "",
                },
                {
                    label: "Heading",
                    key: "noteHeader",
                    sortable: true,
                    tdClass: "",
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                    tdClass: "",
                },
                {
                    label: "Work Time",
                    key: "workTime",
                    sortable: true,
                    tdClass: "",
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    tdClass: "",
                },
            ],
        },
        requestTypes: [],
        tabIndex: 0,
        selectedNote: null,
        selectedNoteIndex: null,
        finalRequestTypeSelected: false,
        isStatusClosed: false
    }),
    created() {
        this.$store.commit("setRequestTypesRequest", 0);
        this.formData.otpEmail.value = this.selectedJobCard.clientEmail;
        this.getWorkTypes();
        this.getRequestTypes().then((response) => {
            if (response.data.length !== 0) {
                this.$store.commit("secondaryRequestTypeClear", []);
                this.secondaryRequestTypes.push({
                    value: null,
                    list: response.data,
                });
                if (
                    this.selectedJobCard.ticketRequestType !== undefined &&
                    this.selectedJobCard.ticketRequestType !== null
                ) {
                    this.secondaryRequestTypes[0].value =
                        this.selectedJobCard.ticketRequestType.id;

                      this.secondaryRequestTypes[0].description =  this.selectedJobCard.ticketRequestType.description

                    if (
                        this.selectedJobCard.ticketRequestType.subRequestTypes
                            .length > 0
                    ) {
                        let subtypes =
                            this.selectedJobCard.ticketRequestType
                                .subRequestTypes[0];
                        let itemInList = response.data.find((element) =>element.id === this.selectedJobCard.ticketRequestType.id);                      

                        while (subtypes !== undefined) {
                            
                            this.secondaryRequestTypes.push({
                                value: subtypes.id,
                                list: itemInList.subRequestTypes,
                                description: subtypes.description
                            });
                            itemInList = itemInList.subRequestTypes.find(
                                (element) => element.id === subtypes.id
                            );
                            subtypes = subtypes.subRequestTypes[0];
                        }
                    }
                }
            }
        });
    },
    mounted() {
        setTimeout(() => {
            this.notesTableData.isLoading = false;
        }, 200);
        if (!this.selectedJobCard.notes.length) {
            this.notesTableData.isLoading = false;
        }
    },
    methods: {
        ...mapActions([
            "getJobCardNotes",
            "getJobCardLabour",
            "loadWorkTypes",
            "sendOtpToEmail",
            "searchRequestType",
            "sendOtpToClient",
            "checkOtpForClient",
            "getRequestTypes",
            "downloadSummaryReport"
        ]),
        validateModalForm() {
            for (const field in this.formData) {
                if (this.requiredFields.includes(field) && !this.formData[field].value) {
                    this.formData[field].error = `${this.formData[field].label} is required.`;
                } else {
                  this.formData[field].error = ``;
                }
            }

            this.isRequired = true;
        },
        validateDate(){
            this.validateModalForm();

            if (this.formData.toDate.value <= this.formData.fromDate.value) {
                this.formData.toDate.error = `${this.formData.fromDate.label} must be less than ${this.formData.toDate.label}.`;
            } else {
                this.formData.fromDate.error = ``;
            }
        },
        clearValues() {
            this.isRequired = false;
            this.formData.date.value = null
            this.formData.date.error = null
            this.formData.fromDate.value = null
            this.formData.fromDate.error = null
            this.formData.toDate.value = null
            this.formData.toDate.error = null
            this.formData.hours.value = 0
            this.formData.hours.error = null
        },
        clearMessage() {
            setTimeout(() => {
                this.otpMessage = null;
            }, 10000);
        },
        addTimeToBulkValue(timeAdded) {
            const newTime =
                parseFloat(this.formData.hours.value) + parseFloat(timeAdded);
            this.formData.hours.value = newTime;
            this.validateModalForm();
        },
        removeRequiredField(field) {
            const index = this.requiredFields.indexOf(field);
            if (index > -1) {
                this.requiredFields.splice(index, 1);
                this.formData[field].error = ""
            }
        },
        addRequiredField(value) {
            const index = this.requiredFields.indexOf(value);
            if (index < 0) {
                this.requiredFields.push(value);
            }
        },
        getWorkTypes() {
            this.loadWorkTypes(0)
                .then((res) => {
                    this.workTypes = res.data;
                })
                .catch(() => {
                    this.state = "show";
                });
        },
        loadNextRequestType(definedItem, index) {
            let itemIndex = definedItem.list.findIndex(
                (element) => element.id === definedItem.value
            );
            if (index + 1 < this.secondaryRequestTypes.length) {
                let listLength = this.secondaryRequestTypes.length;
                for (let i = index + 1; i < listLength; i++) {
                    this.secondaryRequestTypes.pop();
                }
                this.finalRequestTypeSelected = false;
            }
            if (definedItem.list[itemIndex].subRequestTypes.length > 0) {
                this.secondaryRequestTypes.push({
                    value: null,
                    list: definedItem.list[itemIndex].subRequestTypes,
                });
                this.finalRequestTypeSelected = false;
            } else {
                // this.selectedTrigger.technitianGroup = definedItem.list[itemIndex].technitianGroup
                // this.selectedTrigger.technitianGroupId = definedItem.list[itemIndex].technitianGroupId
                this.finalRequestTypeSelected = true;
            }
            // this.loadTechs()
        },
        closeClientCompletedSignOffModal() {
            this.$bvModal.hide("clientCompletedSignOffModal");
        },
        // showOtpSelection(){
        //   if (this.formData.signOffType.value=="2"){
        //     this.optEmailAddress.hide();
        //     this.optEmailenter.show();
        //   }
        //   else{
        //     // this.optEmailAddress.show();
        //     // this.optEmailenter.hide();
        //   }
        // },
        isFormDataValid() {
            return  Object.values(this.formData).find(value => value.error !== '') == null;
        },
        sendOtpForm() {
            this.otpstate = "sending";
            let email = "";
            if (this.formData.otpEmail.value !== null) {
                email = this.formData.otpEmail.value;
            }

            if (this.formData.signOffWorkType.value === null) this.formData.signOffWorkType.value = 0;
            
            this.validateModalForm()

            if (this.isFormDataValid()) {
                let details = {
                  signOffType: parseInt(this.formData.signOffType.value),
                  emailAdress: email,
                  comments: this.formData.signOffComments.value,
                  dateTime: this.formData.date.value,
                  date: this.formData.date.value,
                  workTypeID: parseInt(this.formData.signOffWorkType.value),
                  hours: parseFloat(this.formData.hours.value), //(this.signOffHours),
                  ticketId: this.selectedJobCard.ticketId,
                  toDateTime: null,
                  fromDateTime: null,
                };

                if (!this.isBulkTime && this.formData.toDate.value !== null > 0) {
                    let a = new Date(this.formData.date.value);
                    let b = new Date(this.formData.date.value);

                    a.setHours(this.formData.toDate.value.substr(0, 2));
                    a.setMinutes(this.formData.toDate.value.substr(3, 2));
                    a.setHours(a.getHours() + 2);

                    b.setHours(this.formData.fromDate.value.substr(0, 2));
                    b.setMinutes(this.formData.fromDate.value.substr(3, 2));
                    b.setHours(b.getHours() + 2);
                        
                    //check if dates must be swapped
                    if (b > a)
                    {
                        var tdate = a;
                        a = b;
                        b = tdate;
                        var fdate = this.formData.toDate.value;
                        this.formData.toDate.value = this.formData.fromDate.value;
                        this.formData.fromDate.value = fdate;
                    }

                    //toDate = a
                    details.toDateTime = a;
                    //fromDate = b
                    details.fromDateTime = b;

                    let ta = new Date(a).getTime();
                    let tb = new Date(b).getTime();
                    let m = ta - tb;
                    let diff = m / 1000;
                    diff = diff / 60;
                    let mins = Math.abs(Math.round(diff));
                    this.formData.hours.value = (mins / 60).toFixed(2);
                    // this.formData.hours.value = this.diff_minutes(a,b) / 60
                    details.hours = this.formData.hours.value;
                } else {
                //add something that calculates the minutes and then adds it
                    details.hours = this.formData.hours.value;
                }
              this.$store.commit("setSendOtpToEmailRequest", details);

              this.sendOtpToEmail()
                  .then(() => {
                      this.otpMessage = "Email sent!";
                      this.otpColour = "success";
                      this.clearMessage();
                      //this.formData.signOffType.value = "2"
                      this.clearValues();
                      this.getSelectedJobCardNotes();
                      this.getSelectedJobCardLabour();
                      this.otpstate = "idle";
                  })
                  .catch(() => {
                      this.otpMessage = "Could not send OTP mail.";
                      this.otpColour = "danger";
                      this.otpstate = "idle";
                  });
            }
            else {
                this.otpMessage = "Please complete the form.";
                      this.otpColour = "danger";
                      this.otpstate = "idle";
            }
        },

        getSelectedJobCardNotes() {
            if (this.$store.state.selectedJobCard === null) {
                this.goBackToSearch();
            }
            let request = {
                ticketId: this.selectedJobCard.ticketId,
            };
            this.$store.commit("setJobCardIdRequest", request);
            this.getJobCardNotes().then(() => {
                this.state = "show";
            });
        },

        getSelectedJobCardLabour() {
            if (this.$store.state.selectedJobCard === null) {
                this.goBackToSearch();
            }
            let request = {
                ticketId: this.selectedJobCard.ticketId,
            };
            this.$store.commit("setJobCardIdRequest", request);
            this.getJobCardLabour().then(() => {
                this.$store.commit("setSelectedJobCard", this.selectedJobCard);
                this.state = "show";
            });
        },

        openClientSignOffModal() {
            this.clearValues();
            if (this.selectedJobCard.isSignedOff) {
                this.$bvModal.show("clientCompletedSignOffModal");
            } else {
                this.$bvModal.show("clientSignOffModal");
            }
        },

        downloadSummary() {
            this.otpstate="downloading"

            let request = {
                ticketId: this.selectedJobCard.ticketId,
            };
            this.$store.commit("setJobCardIdRequest", request.ticketId);

            this.downloadSummaryReport()
            .then((response) => {
                        const linkSource = `data:application/pdf;base64,${response.data.base64Data}`;
                        const downloadLink = document.createElement("a");

                        downloadLink.href = linkSource;
                        downloadLink.download = response.data.fileName;
                        downloadLink.click();
                        this.otpstate = "idle";
                    })
                    .catch(() => {
                        this.otpMessage =
                            "Could not download the summary Report";
                        this.otpColour = "danger";
                        this.otpstate = "idle";
                    });
        },

        closeClientSignOffModal() {
            this.$bvModal.hide("clientSignOffModal");
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ["bg-primary", "text-white"];
            } else {
                return ["text-black-50"];
            }
        },
        checkOtp() {
            this.otpstate = "sending";
            this.clearMessage();
            debugger
            if (this.formData.otp.value !== null) {


                if (this.formData.signOffWorkType.value === null) this.formData.signOffWorkType.value = 0;

                let details = {
                  signOffType: parseInt(this.formData.signOffType.value),
                  number: this.formData.otp.value,
                  ticketId: this.selectedJobCard.ticketId
                
                };

                this.$store.commit("setsendOtpToClientRequest", details);
                this.checkOtpForClient()
                    .then((response) => {
                        this.otpMessage = "Successful signoff!";
                        this.otpColour = "success";
                        if (this.formData.signOffType.value === 1)
                            this.selectedJobCard.isSignedOff = true;
                        this.selectedJobCard.singnOffDate = new Date();
                        this.clearMessage();
                        this.otpstate = "idle";
                        this.closeClientSignOffModal();

                        if (response.data=="Full")
                        {
                            this.selectedJobCard.isSignedOff = true;
                            //display the survey selection
                            this.$root.$emit('openSurveyModalEvent',{forceSurvey:true})
                        }
                        else
                            window.location.reload();
                    })
                    .catch(() => {
                        this.otpMessage =
                            "Could not signoff using the OTP provided";
                        this.otpColour = "danger";
                        this.otpstate = "idle";
                    });
            }
        },

        openNoteModal() {
            this.$bvModal.show("addNoteModal");
        },
        closeNoteModal() {
            this.$bvModal.hide("addNoteModal");
        },

        showRemoveNoteModal(rowItem) {
            this.$bvModal.show("removeNoteModal");
            this.selectedNote = rowItem;
            this.selectedNoteIndex =
                this.selectedJobCard.notes.indexOf(rowItem);
        },
        hideRemoveNoteModal() {
            this.$bvModal.hide("removeNoteModal");
        },
        createNewNote() {
            const note = {};
            note.noteHeader = this.noteDetails.noteHeader;
            note.note = this.noteDetails.note;
            note.noteDate = new Date();
            note.user = localStorage.getItem("user");
            this.selectedJobCard.notes.unshift(note);
            this.closeNoteModal();
            this.noteDetails.noteHeader = null;
            this.noteDetails.note = null;
        },
        removeNote() {
            this.selectedJobCard.notes.splice(this.selectedNoteIndex, 1);
            this.hideRemoveNoteModal();
        },
    },
    computed: {
        ...mapState(["selectedJobCard", "secondaryRequestTypes"]),
        noteTableRows() {
            return this.selectedJobCard.notes.length;
        },
    },
};
</script>
