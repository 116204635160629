<template>
    <div>
        <b-row>
            <b-col cols="12" class="text-right">
                <b-button variant="primary" @click="openChecklistModal()" :disabled="isStatusClosed">Add Checklist</b-button>
            </b-col>
        </b-row>


        <b-row>
            <b-col cols="12">
                <b-table striped hover :items="selectedJobCard.checkLists" :fields="tableData.tableColumns" 
                    :busy="tableData.isLoading" sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(actions)="row">
                        <b-row align-h="end">
                            <b-button @click="viewChecklist(row.item)" variant="primary" size="sm" v-if="row.item.completed === true">View
                            </b-button>
                            <b-button @click="openCompleteChecklistModal(row.item)" variant="primary" size="sm" v-if="row.item.completed === false && isNewChecklist(row.item.checkListId) === false" :disabled="isStatusClosed">Complete
                            </b-button>
                            <b-button @click="removeFromList(row.item)" variant="red" size="sm" class="ml-2"
                                v-if="row.item.completed !== true">Remove
                            </b-button>
                        </b-row>
                    </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
                <b-pagination
                v-model="tableData.currentPage"
                :total-rows="tableData.rows"
                :per-page="tableData.perPage"
                ></b-pagination>
        </b-row>

        <b-modal id="addItem-modal" hide-footer @close="hideModal()" size="xl">
            <b-row>
                <b-col>
                    <h4>Checklist Search</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                <b-alert :show="duplicateChecklistMessage"
                         dismissible
                         variant="warning">This checklist is already part of the list and cannot be added again</b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <label>Description</label>
                    <b-form-input v-model="search.description"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Date Created From</label>
                    <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
                </b-col>
                <b-col cols="4">
                    <label>Date Created To</label>
                    <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
                </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
                    <b-button variant="primary" squared @click="callSearchChecklists()">Search</b-button>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="12">
                    <b-table striped hover :items="checklistTableData.dataSource" :fields="checklistTableData.tableColumns" 
                        :busy="checklistTableData.isLoading" @row-clicked="addChecklistRow" sort-icon-left>
                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>
                        <template #cell(checkListDate)="row">
                            <b-row align-v="center">
                                <span class="mr-auto">{{row.item.checkListDate | dateTimeFilter}}</span>
                            </b-row>
                        </template>
                        
                        <template #cell(actions)="row">
                            <b-row align-h="end">
                                <b-button @click="addChecklistRow(row.item)" class="btn-icon" size="sm">
                                    <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>

                        
                    </b-table>
                </b-col>
            </b-row>
            <b-row align-h="center" >
                    <b-pagination
                    v-model="checklistTableData.currentPage"
                    :total-rows="checklistTableData.rows"
                    :per-page="checklistTableData.perPage"
                    ></b-pagination>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" @click="hideModal" class="mr-2 ml-2" squared>Close</b-button>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal :title="selectedChecklist?.description" id="complete-checklist-modal" v-if="selectedChecklist" hide-footer @close="hideModal()" size="xl">
            <complete-checklist @complete-checklist-request="onCompleteChecklistSuccess"/>
        </b-modal>

        <b-modal :title="completedChecklist?.description" id="view-completed-checklist-modal" hide-footer @close="hideModal()" size="xl">
            <completed-checklist-view/>
        </b-modal>

        <b-modal id="show-image-modal" hide-footer size="xl" no-header @close="closeModal()">
            <b-row v-if="showIndex < 0">
                <b-col cols="3" v-for="(item, index) in imageQuestion.answerImages" :key="index" class="text-center">
                    <img v-if="item.base64Data !== null" :src="returnFileObject(item)" alt="" class="fix-height-images" @click="changeShowIndex(index)">
                    <b-spinner v-if="item.base64Data === null"></b-spinner>
                </b-col>
            </b-row>
            <b-row v-if="showIndex > -1">
                <b-col cols="12" class="text-center">
                    <img v-if="imageQuestion.answerImages[showIndex].base64Data !== null" :src="returnFileObject(imageQuestion.answerImages[showIndex])" 
                        alt="" class="modal-images" @click="changeShowIndex(-1)">
                    <b-spinner v-if="imageQuestion.answerImages[showIndex].base64Data === null"></b-spinner>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
// import CompleteChecklistModal from '@/components/jobCards/CompleteChecklistModal.vue';
import completeChecklist from '../../components/completeChecklist.vue'
import completedChecklistView from '../../views/checklists/completedChecklistView.vue'

export default {
    props: {
        isStatusClosed: {
            type: Boolean,
            required: true
        }
    },
    components: {
        completeChecklist,
        completedChecklistView
    },
    data: () => ({
        dtoTicketCheckList:{
            checkListId : null,
            //description : null,
            //questionareType : null,
            ticketId : null,
            completed : null,
            //checkListType : null,
        },

        newlyAddedChecklistIds: [],
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Type',
                    key: 'checkListType',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Completed',
                    key: 'completed',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        duplicateChecklistMessage: false,
        selectedChecklistAnswers: {},
        search:{
            description: null,
            dateCreatedFrom: null,
            dateCreatedTo: null
        },
        checklistTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Procedure Number',
                    key: 'procedureNumber',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'checkListDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Created By',
                    key: 'createdBy',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        viewedChecklist: {categories: []},
        
    showIndex: -1,
        imageQuestion: {
            answerImages: []
        }
    }),
    created(){
        setTimeout(() => {
            this.selectedJobCard.checkLists
            this.tableData.isLoading = false
        }, 200)
    },
    methods:{
        ...mapActions(['searchChecklists', 'getChecklistResponse', 'getChecklistInformation'
                     , 'downloadDocumentForJobCard','getTicketChecklists','saveJobCardChecklist']),
        
    changeShowIndex(newIndex){
      this.showIndex = newIndex
    },
    onCompleteChecklistSuccess() {
      this.$root.$emit('bv::hide::modal', 'complete-checklist-modal', '#btnShow')
    },
    openCompleteChecklistModal(checklist) {
            let item = {checkListId: checklist.checkListId}
            this.$store.commit('setSelectedChecklist', item)
            this.getChecklistInformation()
            .then(() => {
                this.selectedChecklist.ticketId = checklist.ticketId;
                this.$root.$emit('bv::show::modal', 'complete-checklist-modal', '#btnShow')
            })
        },
    openImageModal(q){
      this.imageQuestion = q
      this.$root.$emit('bv::show::modal', 'show-image-modal', '#btnShow')
      this.showIndex = -1

      for (let i = 0; i < q.answerImages.length; i++) {
        const element = q.answerImages[i];
        this.$store.commit('setDownloadDocumentForJobCardRequest', element.documentId)
        this.downloadDocumentForJobCard()
        .then((res) => {
          element.base64Data = res.data.base64Data
          element.contentType = res.data.contentType
        })
      }

      
    },
        removeFromList(item){
            for (let i = 0; i < this.selectedJobCard.checkLists.length; i++) {
                const element = this.selectedJobCard.checkLists[i];
                if (element.checkListId === item.checkListId) {
                    this.selectedJobCard.checkLists.splice(i,1)
                    return
                }
            }
        },
    viewChecklist(checklist){
        this.$store.commit('setCompletedChecklist', checklist)
        this.$root.$emit('bv::show::modal', 'view-completed-checklist-modal', '#btnShow')
    },
        openChecklistModal(){
            this.$root.$emit('bv::show::modal', 'addItem-modal', '#btnShow')
            this.duplicateChecklistMessage = false
            this.callSearchChecklists()
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'addItem-modal', '#btnShow')
        },


        addChecklistRow(rowItem){
        
            let found = false
            for (let i = 0; i < this.selectedJobCard.checkLists.length; i++) {
                const element = this.selectedJobCard.checkLists[i];
                if (rowItem.checkListId === element.checkListId) {
                    found = true
                }   
            }
            if (found === false) {
                 //save the cheecklist
                this.dtoTicketCheckList.ticketId = this.selectedJobCard.ticketId
                this.dtoTicketCheckList.checkListId = rowItem.checkListId
                this.dtoTicketCheckList.completed = false
                this.$store.commit("setJobcardChecklistRequest", this.dtoTicketCheckList);
                
                this.saveJobCardChecklist().then(() => {
                // get the checlists from the database
                this.getSelectedJobCardCheckLists();
                this.state = "show";
            });
                
                
                // let newChecklist = rowItem
                // newChecklist.completed = false
                // this.selectedJobCard.checkLists.push(rowItem)
                // this.newlyAddedChecklistIds.push(rowItem.checkListId);
                this.hideModal()
            } else {
                this.duplicateChecklistMessage = true
            }
        },
        isNewChecklist(checkListId) {
            return this.newlyAddedChecklistIds.includes(checkListId);
        },
        clearFilter(){
            this.search = {
                description: null,
                dateCreatedFrom: null,
                dateCreatedTo: null
            }
        },
        callSearchChecklists(){
            this.checklistTableData.isLoading = true
            
            let request = []
            if(this.search.description !== null){
                request.push({"key":"description","value": this.search.description})
            }
            if(this.search.dateCreatedFrom !== null){
                request.push({"key":"createdDateFrom","value": this.search.dateCreatedFrom})
            }
            if(this.search.dateCreatedTo !== null){
                request.push({"key":"createdDateTo","value": this.search.dateCreatedTo})
            }
            
            request.push({"key":"fkcheckListTypeid","value": 1})
            request.push({"key":"active","value": 'true'})

            this.$store.commit('setChecklistSearchRequest', request)
            this.searchChecklists()
            .then((res) => {
                this.checklistTableData.dataSource = res.data
                this.checklistTableData.isLoading = false
            })
            .catch(() => {
                this.checklistTableData.isLoading = false
            })
        },
        
        returnFileObject(item){
            //console.log('image')
            let base64 = 'data:' + item.contentType +';base64,'+ item.base64Data
            return base64
        },

        getSelectedJobCardCheckLists() {
            console.log ('retrieving cheklists')
            if (this.$store.state.selectedJobCard === null) {
                this.goBackToSearch();
            }
            // let request = {
            //     ticketId: this.selectedJobCard.ticketId,
            // };
            this.$store.commit("setJobCardIdRequest", this.selectedJobCard.ticketId);
            this.getTicketChecklists().then(() => {
                console.log('this.selectedJobCard',this.selectedJobCard)
                this.$store.commit("setSelectedJobCard", this.selectedJobCard);
                this.state = "show";
            });
        }

    },
    computed:{
        ...mapState(['selectedJobCard', 'selectedChecklist', 'completedChecklist'])
    }
}
</script>