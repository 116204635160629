<template>
  <div>
    <b-row>
      <b-col class="text-right">
        <b-button variant="primary" squared class="ml-2" @click="openAddMaterialModal">Add Material</b-button>
      </b-col>
    </b-row>
    
    <b-row>
      
      <b-col>
        <b-table striped hover
                 :items="tableData.dataSource"
                 :fields="tableData.tableColumns"
                 :busy="tableData.isLoading"
                 :per-page="tableData.resultsPerPage"
                 id="allocatedTable"
                 :current-page="tableData.currentPage"
                 sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button @click="openRemoveMaterialModal(row.item)" variant="red" size="sm" class="ml-2">
                Remove
              </b-button>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row align-h="center" >
      <b-pagination
          v-model="tableData.currentPage"
          :total-rows="rows"
          :per-page="tableData.resultsPerPage"
          aria-controls="allocatedTable"
      ></b-pagination>
    </b-row>

    <b-modal class="p-0" id="addMaterialModal" size="lg" hide-footer title="Add Allocated Material" @close="closeAddMaterialModal">
      <b-row v-if="!isActive">
        <b-col>
          <label>Item Used</label>
          <b-input-group>
            <b-form-input disabled v-model="materialDetails.description"></b-form-input>
            <b-input-group-append>
              <b-button size="sm" text="Button" variant="secondary" @click="toggleItemSearch">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <label>Quantity</label>
          <b-form-input v-model="materialDetails.quantity"></b-form-input>
        </b-col>
      </b-row>

      <b-row v-if="isActive">
          <b-col cols="4">
              <label>Ticket Number</label>
              <b-form-input v-model="stocksearch.referenceNumber"></b-form-input>
          </b-col>
        </b-row>
      
      <hr class="mx-3"  v-if="isActive" />
      <b-row v-if="isActive">
          <b-col cols="12" class="text-right">
              <b-button variant="red" squared class="mr-2" @click="clearFilter()">Cancel</b-button>
              <b-button variant="primary" squared @click="searchStock()">Search</b-button>
          </b-col>
      </b-row>
      <b-row v-if="isActive">
        
        <b-table striped hover
                 :items="materialTableData.dataSource"
                 :fields="materialTableData.tableColumns"
                 :busy="materialTableData.isLoading"
                 :per-page="materialTableData.resultsPerPage"
                 @row-clicked="addItem"
                 id="materialSearchTable"
                 :current-page="materialTableData.currentPage"
                 sort-icon-left>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
          </template>


          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button size="sm" class="btn-icon" @click="addItem(row.item)">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>
        </b-table>
      </b-row>
      <b-row v-if="isActive" align-h="center" >
        <b-pagination
            v-model="materialTableData.currentPage"
            :total-rows="materialRows"
            :per-page="materialTableData.resultsPerPage"
            aria-controls="materialSearchTable"
        ></b-pagination>
      </b-row>
      
      <hr class="mx-3">
      
      <b-row v-if="!isActive">
        <b-col>
          <div class="d-flex justify-content-end">
            <div>
              <b-button variant="red" squared class="ml-2" @click="closeAddMaterialModal">Cancel</b-button>
            </div>
            <div>
              <b-button variant="primary" squared class="ml-2" @click="addMaterial">Save</b-button>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="isActive">
        <b-col>
          <div class="d-flex justify-content-end">
            <div>
              <b-button variant="red" squared class="ml-2" @click="toggleItemSearch">Cancel</b-button>
            </div>
            <div>
              <b-button variant="primary" squared class="ml-2">Save</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal class="p-0" id="removeMaterialModal" size="md" hide-footer title="Remove Allocated Cost" @close="closeRemoveMaterialModal">
      <b-row>
        <b-col cols="12">
          <label>Are you sure you wish to delete the following allocated material?</label>
        </b-col>
      </b-row>
      <hr class="mx-3">
      <b-row>
        <b-col>
          <div class="d-flex justify-content-end">
            <div>
              <b-button variant="red" squared class="ml-2" @click="removeMaterial">Remove</b-button>
            </div>
            <div>
              <b-button variant="red" squared class="ml-2" @click="closeRemoveMaterialModal">Cancel</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  props: {
    isStatusClosed: {
      type: Boolean,
      required: true
    }
  },
  name: "AllocatedMaterial",
  data: () => ({
    
    stocksearch: {
      referenceNumber: null
        },
    materialDetails: {
      tickedId: null,
      itemCode: null,
      description: null,
      included: true,
      quantity: null,
      price: null,
    },
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        'itemCode',
        {
          label: 'Item Code',
          key: 'itemCode',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Description',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        // {
        //   label: 'Included',
        //   key: 'included',
        //   sortable: true,
        //   tdClass: '',
        // },
        {
          label: 'Price',
          key: 'price',
          sortable: true,
          tdClass: '',
          formatter: (value, key, item) => {
            if (item.price==null){ item.price = 0}
            return "R " + (item.price).toFixed(2)
          }
        },
        {
          label: 'Quantity',
          key: 'quantity',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Total',
          key: 'total',
          sortable: true,
          tdClass: '',
          formatter: (value, key, item) => {
            if (item.price==null){ item.price = 0}
            if (item.quantity==null){ item.quantity = 0}
            return "R " + (item.price * item.quantity).toFixed(2)
          }
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    materialTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Item Code',
                    key: 'itemCode',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Product Reference',
                    key: 'productRef',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'createdDate',
                    sortable: false
                },
                {
                    label: 'Price Excl. VAT',
                    key: 'priceExVat',
                    sortable: false,
                    tdClass: 'text-right',
                    thClass: 'text-right'
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'text-right'
                }
            ]
    },
    selectedMaterial: null,
    selectedMaterialIndex: null,
    isActive: false,
    ogStockList: false
  }),
  mounted() {
    setTimeout(() => {
      this.tableData.isLoading = false
      this.tableData.dataSource = this.selectedJobCard.allocatedMaterials
      this.tableData.isLoading = false
    },200)
  },
  methods: {
    ...mapActions(['searchProducts']),
    
    clearFilter(){
        this.stocksearch = {
          assetType: null,
          assetNumber: null
        }
        this.materialTableData.isLoading = true
        this.materialTableData.dataSource = this.ogStockList
        this.materialTableData.isLoading = false
      },
      searchStock(){
        this.materialTableData.isLoading = true;

        let referenceNumber = this.stocksearch.referenceNumber !== null ? this.stocksearch.referenceNumber.toLowerCase() : null;
        let newArray = this.ogStockList.filter(function (el) {
            return (
            (el.productRef !== null ? el.productRef.toLowerCase().includes(referenceNumber) : false)
            );
        });

        this.materialTableData.dataSource = newArray;
        this.materialTableData.isLoading = false;
      },
    openAddMaterialModal() {
      this.$bvModal.show('addMaterialModal')
      
            
      let request = []

      this.$store.commit('setProductSearchRequest', request)
      this.searchProducts()
      .then((res) => {
        //console.log('getAvailableMaterials',res)
        
        this.ogStockList = res.data
        this.ogStockList.forEach(element => {
          element.createdDate = element.createdDate.substring(0,10) //element.createdDate.toString().replace('T', ' ')
        });
        this.materialTableData.dataSource = res.data
        this.materialTableData.isLoading = false
      })
    },
    closeAddMaterialModal() {
      this.$bvModal.hide('addMaterialModal')
    },
    openRemoveMaterialModal(rowItem) {
      this.$bvModal.show('removeMaterialModal')
      this.selectedMaterial = rowItem
      this.selectedMaterialIndex = this.tableData.dataSource.indexOf(rowItem)
    },
    closeRemoveMaterialModal() {
      this.$bvModal.hide('removeMaterialModal')
    },
    addItem(rowItem) {
      //console.log(rowItem)
      this.materialDetails.description = rowItem.description
      this.materialDetails.itemCode = rowItem.itemCode
      this.materialDetails.price = rowItem.priceExVat
      this.isActive = false
    },
    addMaterial() {
      //console.log(this.materialDetails);
      const item = {}
      item.itemCode = this.materialDetails.itemCode
      item.description = this.materialDetails.description
      item.quantity = this.materialDetails.quantity
      item.price = this.materialDetails.price
      item.included = this.materialDetails.included
      item.total = item.price * item.quantity
      this.tableData.dataSource.push(item)
      this.closeAddMaterialModal()
      this.materialDetails.quantity = null
      this.materialDetails.description = null
    },
    toggleItemSearch() {
      this.isActive = !this.isActive;
    },
    removeMaterial() {
      this.tableData.dataSource.splice(this.selectedMaterialIndex, 1)
      this.closeRemoveMaterialModal()
    },
  },
  
  computed: {
    ...mapState(['selectedJobCard']),
    rows () {
      return this.tableData.dataSource.length
    },
    materialRows() {
      return this.materialTableData.dataSource.length
    },
  },
}
</script>