<template>
   <div>
        <div v-if="(statusid === 5) && this.sentSurveyName == null && this.selectedJobCard.surveySent == null
            && this.tableData.dataSource.length == 0" class="col-md-12 text-right">
            <b-button @click="sendSurvey()" >Send Survey</b-button>
        </div>

        <b-row  v-if="this.sentSurveyName != null">
            <b-col>
                <div class="text-right">
                    <p>Selected Survey: <span class="bold">{{this.sentSurveyName}}</span></p>
                </div>
            </b-col>
        </b-row>
        <b-row  v-if="surveyInfo.completedDate !== null">
            <b-col>
                <div class="text-right">
                    <p>Date Completed: <span class="bold">{{surveyInfo.completedDate | dateTimeFilter}}</span></p>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <b-table striped hover 
                         :items="tableData.dataSource" 
                         :fields="tableData.tableColumns" 
                         :busy="tableData.isLoading"
                         :per-page="tableData.resultsPerPage"
                         id="surveyTable"
                         :current-page="tableData.currentPage"
                         sort-icon-left>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                    
                    <template #cell(answer)="row">
                        <b-row align-v="center">
                            <span v-if="row.item.question.answerType=='Image'"><button class="badge badge-green" @click="showImage(row.item.answer)">View Image</button></span>
                            <span v-else class="mr-auto">{{row.item.answer}}</span>
                        </b-row>
                    </template>

                    <!-- <template>
                        <b-row align-v="center" align-h="end">
                            <b-button class="btn-icon" size="sm">
                                <b-icon-chevron-right></b-icon-chevron-right>
                            </b-button>
                        </b-row>
                    </template> -->

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="surveyTable"
                ></b-pagination>
        </b-row>

        

        <b-modal
            id="survey-image-modal"
            hide-footer
            no-header
            @close="closeModal()">
            <b-row align-h="center">
                <b-col class="text-center">
                    <img
                        v-if="imageItem !== null"
                        :src="returnFileObject(imageItem)"
                        alt=""
                        class="fix-height-images"/>
                    <!-- <b-spinner v-if="imageItem === null"></b-spinner> -->
                </b-col>
            </b-row>
            
        </b-modal>

    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
export default {
    props: {
        isStatusClosed: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        imageItem: null,

        statusid: 0,
        sentSurveyName: null,
        surveyInfo: {
            completedDate: null,
            ticketID: null,
            surveyID: null,
            completed: null,
            completedBy: null,
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Question',
                    key: 'question.question',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Answer',
                    key: 'answer',
                    sortable: true,
                    tdClass: ''
                },
                // {
                //     label: '',
                //     key: 'actions',
                //     sortable: false,
                //     tdClass: ''
                // }
            ]
        }
    }),
  
    created() {
      this.searchSurvey()
    },
    mounted(){
      setTimeout(() => {
        this.statusid = this.selectedJobCard.statusId
        if (this.selectedJobCard.selectedSurveys.length > 0){
         console.log("Survey data", this.selectedJobCard.selectedSurveys[0])
        this.sentSurveyName = this.selectedJobCard.selectedSurveys[0].description
        }
      }, 200)
    },
    

    methods: {
      ...mapActions(["getSurveyList","downloadFileByName"]),
      
      sendSurvey(){
        this.$root.$emit('openSurveyModalEvent',{forceSurvey:true})
      },

      searchSurvey() {
        this.tableData.isLoading = false
        this.getSurveyList()
        .then((response) => {

          this.tableData.dataSource = response.data[0].answers
          this.surveyInfo = {
              completedDate: response.data[0].completedDate,
              ticketID: response.data[0].ticketID,
              surveyID: response.data[0].surveyID,
              completed: response.data[0].completed,
              completedBy: response.data[0].completedBy,
          }

          //console.log(" this.tableData.dataSource", this.tableData.dataSource)

          if (response.data === null) {
            this.state = false
          }
          this.tableData.isLoading = false
        })
        .catch( () => {
          this.tableData.isLoading = false
        })
      },

      showImage(item){
        //console.log("item",item)
        this.$store.commit('setDownloadDocumentName', item)
        this.downloadFileByName()
        .then((res) => {
            this.imageItem = res.base64Data
           // console.log("imageItem",this.imageItem)
            this.openImageModal()
        })
      
      },

      openImageModal() {
            this.$root.$emit("bv::show::modal", "survey-image-modal", "#btnShow");
        },

        closeModal() {
            this.$root.$emit("bv::hide::modal", "survey-image-modal", "#btnShow");
        },

        returnFileObject(item) {
            let base64 =
                "data:" + item.contentType + ";base64," + item;
            return base64;
        },

    },
  
    computed: {
        ...mapState(['selectedJobCard']),
      rows() {
        return this.tableData.dataSource.length
      },
    },  
}
</script>