<template>
  <div>
    <b-row>
      <b-col>
        <b-table striped hover
                 :items="tableData.dataSource"
                 :fields="tableData.tableColumns"
                 :busy="tableData.isLoading"
                 :per-page="tableData.resultsPerPage"
                 id="costTable"
                 :current-page="tableData.currentPage"
                 sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
          </template>
          
          <!-- <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button variant="primary" size="sm" class="ml-2" @click="openViewDocument(row.item)">
                View Document
              </b-button>
            </b-row>
          </template> -->
          
          <template #cell(startDate)="row">
            {{row.item.startDate | dateTimeFilter}}
          </template>
          
          <template #cell(endDate)="row">
            {{row.item.endDate | dateTimeFilter}}
          </template>

          <template #cell(cost)="row">
            R {{addCommas(row.item.cost.toFixed(2))}}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row align-h="center" >
      <b-pagination
          v-model="tableData.currentPage"
          :total-rows="rows"
          :per-page="tableData.resultsPerPage"
          aria-controls="costTable"
      ></b-pagination>
    </b-row>
    <b-modal id="showDocumentModal" hide-footer size="xl" @close="closeViewDocument">
      <b-row>
        <b-col class="text-center">
          <h6>Not yet implemented</h6>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    isStatusClosed: {
      type: Boolean,
      required: true
    }
  },
  state: 'loading',
  name: "AssociatedCost",
  data: () => ({
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Description',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        // {
        //   label: 'Start Date',
        //   key: 'startDate',
        //   sortable: true,
        //   tdClass: '',
        // },
        // {
        //   label: 'End Date',
        //   key: 'endDate',
        //   sortable: true,
        //   tdClass: '',
        // },
        // {
        //   label: 'Remarks',
        //   key: 'remarks',
        //   sortable: true,
        //   tdClass: '',
        // },
        // {
        //   label: 'Included',
        //   key: 'included',
        //   sortable: true,
        //   tdClass: '',
        // },
        {
          label: 'Cost',
          key: 'cost',
          sortable: true,
          tdClass: '',
        }
      ],
    },
    selectedDocument: null,
    selectedDocumentIndex: null,
  }),
  mounted() {
    setTimeout(() => {
      this.tableData.isLoading = true
      this.tableData.dataSource = this.selectedJobCard.costs
      this.data = this.selectedJobCard.costs
      this.tableData.isLoading = false
    },300)
  },
  methods: {
    addCommas(nStr)
        {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2;
        },
    openViewDocument(rowItem) {
      this.$bvModal.show('showDocumentModal')
      this.selectedDocument = rowItem
      this.selectedDocumentIndex = this.tableData.dataSource.indexOf(rowItem)
    },
    closeViewDocument() {
      this.$bvModal.hide('showDocumentModal')
    }
  },
  computed: {
    ...mapState(['selectedJobCard']),
    
    rows () {
      return this.tableData.dataSource.length
    },
    subtotal() {
      let total = 0
      
      if (this.selectedJobCard.cost !== null && this.selectedJobCard.cost !== undefined) {
        for (let i = 0; i < this.selectedJobCard.cost.length; i++) {
          const cost = this.selectedJobCard.cost[i]
          total += cost.cost
        }
      }
      return total
    },
    vat() {
      let total = 0

      if (this.selectedJobCard.cost !== null && this.selectedJobCard.cost !== undefined) {
        for (let i = 0; i < this.selectedJobCard.cost.length; i++) {
          const cost = this.selectedJobCard.cost[i]
          total += (cost.cost * 0.15)
        }
      }
      return total
    },
    total() {
      return (parseFloat(this.subtotal) + parseFloat(this.vat))
    },
  },
}
</script>

<style scoped>

</style>