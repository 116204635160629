<template>
    <div>
        <b-row>
            <b-col cols="12" class="text-right">
                <b-button variant="primary" @click="openAddStockModal()" :disabled="isStatusClosed">Add Stock</b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <b-table striped hover
                         :items="tableData.dataSource"
                         :fields="tableData.tableColumns"
                         :busy="tableData.isLoading"
                         :per-page="tableData.resultsPerPage"
                         id="stockTable"
                         :current-page="tableData.currentPage"
                         sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
<!--                     
                    <template #cell(qty)="row">
                        <b-form-input v-model="row.item.qty" type="number"></b-form-input>
                    </template>
                     -->

                    <!-- <template #cell(actions)="row">
                        <b-row align-h="end">
                            <b-button @click="openRemoveStockModal(row.item)" variant="red" size="sm" class="ml-2">Remove
                            </b-button>
                        </b-row>
                    </template> -->

                  <template #cell(show_details)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="btn-icon" >
                      <span v-if="row.detailsShowing">
                        <b-icon-chevron-left></b-icon-chevron-left>
                      </span>
                      <span v-else>
                        <b-icon-chevron-right></b-icon-chevron-right>
                      </span>
                    
                    </b-button>
                  </template>
            
                  <template #row-details="row">
                    <b-table striped hover
                      selectable
                      selected-variant="primary"
                      responsive="sm"
                      :select-mode='selectModeSingle'
                      :items="row.item.items"
                      :fields="stockItemTableDataSelected.tableColumns"
                      :busy="false"
                      id="stockItemsTable"
                      ref="selectableTable"
                      @row-selected="onStockRowSelected"
                      sort-icon-left>
                      <template #table-busy>
                          <div class="text-center my-2">
                              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                          </div>
                      </template>
                      <template #cell(actions)="row">
                        <b-row align-h="end">
                            <span v-if="row.item == selectedStock[0]">
                                <b-button variant="red" size="sm" class="ml-2" :disabled="isStatusClosed || state === 'loading'" @click="removeStockFromTicket"><b-spinner small v-if="state === 'loading'"></b-spinner> Remove
                              </b-button>
                            </span>
                        </b-row>
                    </template>
                    
                    <!-- <template #cell(actions)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="btn-icon" >
                      <span  @click="removeStockFromTicket">
                        <b-icon-chevron-right></b-icon-chevron-right>
                      </span>
                    
                    </b-button>
                    </template> -->

                    </b-table>
                  </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="stockTable"
                ></b-pagination>
        </b-row>

      <b-modal class="p-0" id="removeStockModal" size="md" hide-footer title="Remove Note" @close="closeRemoveStockModal">
        <b-row>
          <b-col cols="12">
            <label>Are you sure you wish to remove <span v-if="this.selectedStockItem !== null" class="text-red font-weight-bold">{{this.selectedStockItem.manufacturer}} {{this.selectedStockItem.model}}</span>  item?</label>
          </b-col>
        </b-row>
        <hr class="mx-3">
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <div>
                <b-button variant="red" squared class="ml-2" :disabled="state === 'loading'" @click="removeStockItem"><b-spinner small v-if="state === 'loading'"></b-spinner> Remove</b-button>
              >
              </div>
              <div>
                <b-button variant="red" squared class="ml-2" @click="closeRemoveStockModal">Cancel</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-modal>
      
      <b-modal class="p-0" id="addStockModal" size="xl" hide-footer title="Add Stock" @close="closeAddStockModal">
        <b-row>
          <b-col cols="4">
              <label>Stock Type <span class="text-red">*</span></label>
              <b-form-select v-model="selectedStockType" @change="onSelectStockType">
                <b-form-select-option :value="StockTypes.Boot">Loan Account</b-form-select-option>
                <b-form-select-option :value="StockTypes.Client">Client Stock</b-form-select-option>
              </b-form-select>
          </b-col>
          <b-col cols="4">
              <label>Asset Type</label>
              <b-form-input v-model="stocksearch.assetType"></b-form-input>
          </b-col>
          <b-col cols="4">
              <label>Asset Number</label>
              <b-form-input v-model="stocksearch.assetNumber"></b-form-input>
          </b-col>
      </b-row>
      
      <hr class="mx-3" />
      <b-row>
          <b-col cols="12" class="text-right">
              <b-button variant="red" squared class="mr-2" @click="clearFilter()">Clear</b-button>
              <b-button variant="primary" squared @click="searchStock()">Search</b-button>
          </b-col>
      </b-row>
        
        <b-row>
          <b-col>
            <b-table striped hover
              :items="stockTableData.dataSource"
              :fields="stockTableData.tableColumns"
              :busy="stockTableData.isLoading"
              :per-page="stockTableData.resultsPerPage"
              id="availableStockTable"
              :current-page="stockTableData.currentPage"
              sort-icon-left>
              <template #table-busy>
                  <div class="text-center my-2">
                      <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
              </template>
              
              <template #cell(show_details)="row">
                  <b-button size="sm" @click="row.toggleDetails" class="btn-icon" >
                    <span v-if="row.detailsShowing">
                      <b-icon-chevron-left></b-icon-chevron-left>
                    </span>
                    <span v-else>
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </span>
                   
                  </b-button>
              </template>
            
              <template #row-details="row">
                <b-table striped hover
                  selectable
                  selected-variant="primary"
                  responsive="sm"
                  :select-mode='selectMode'
                  :items="row.item.items"
                  :fields="stockItemTableData.tableColumns"
                  :busy="false"
                  id="stockItemsTable"
                  ref="selectableTable"
                 
                  sort-icon-left>
                  <template #table-busy>
                      <div class="text-center my-2">
                          <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                      </div>
                  </template>
                  <template #cell(quantityUsed)="row">
                        <b-form-input v-model="row.item.quantityUsed" type="number" v-if="row.item.available==true"></b-form-input>
                        <b-form-input style="border: none;background: transparent;text-align: center;" disabled="true"  v-model="row.item.quantityUsed" v-else></b-form-input>
                  </template>
                  <template #cell(actions)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="btn-icon" v-if="row.item.available==true">
                      <span  @click="addStockToTicket(row.item)">
                        <b-icon-chevron-right></b-icon-chevron-right>
                      </span>
                    </b-button>
                    <b-form-label  v-else>Stock In Use</b-form-label>
                    </template>

                </b-table>
              </template>
            
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="text-right">
              <b-button variant="red" squared class="mr-2" @click="closeAddStockModal()">Close</b-button> 
              <!-- <b-button v-if="selectedStock.length > 0" variant="primary" squared :disabled="state === 'loading'" @click="addStockToTicket"><b-spinner v-if="state === 'loading'" small></b-spinner> Add</b-button> -->
          </b-col>
        </b-row>
      </b-modal>
    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'

const StockTypes = Object.freeze({
  Client: 0,
  Boot: 1
});

export default {
  props: {
    isStatusClosed: {
      type: Boolean,
      required: true
    }
  },
    data() {
      return {
          selectedStockType: StockTypes.Boot,
          selectMode: 'multi',
          selectModeSingle: 'single',
          selectedStock:[],
          tableData: {
              resultsPerPage: 10,
              currentPage: 1,
              dataSource: [],
              isLoading: true,
              tableColumns: [
                  // {
                  //     label: 'Item Code',
                  //     key: 'itemCode',
                  //     sortable: false,
                  //     tdClass: ''
                  // },
                  // {
                  //     label: 'Description',
                  //     key: 'description',
                  //     sortable: false,
                  //     tdClass: ''
                  // },
                  {
                      label: 'Asset Type',
                      key: 'assetType',
                      sortable: true,
                      tdClass: ''
                  },
                  {
                      label: 'Manufacturer',
                      key: 'manufacturer',
                      sortable: false,
                      tdClass: ''
                  },
                  {
                      label: 'Model',
                      key: 'model',
                      sortable: false,
                      tdClass: ''
                  },
                  // {
                  //     label: 'Serial Number',
                  //     key: 'serialNumber',
                  //     sortable: false,
                  //     tdClass: ''
                  // },
                  // {
                  //     label: 'Location',
                  //     key: 'location',
                  //     sortable: false,
                  //     tdClass: ''
                  // },
                  // {
                  //     label: 'Status',
                  //     key: 'status',
                  //     sortable: false,
                  //     tdClass: ''
                  // },
                  {
                      label: 'Quantity',
                      key: 'quantity',
                      sortable: false,
                      tdClass: 'text-center',
                      thClass: 'text-center'
                  },
                  // {
                  //     label: '',
                  //     key: 'actions',
                  //     sortable: false,
                  //     tdClass: 'text-right'
                  // }
                  {
                      label: '',
                      key: 'show_details',
                      sortable: false,
                      tdClass: 'text-right'
                  }
              ],
          },
          stockTableData: {
              resultsPerPage: 10,
              currentPage: 1,
              dataSource: [],
              isLoading: true,
              tableColumns: [
                  // {
                  //     label: 'Asset Number',
                  //     key: 'assetNumber',
                  //     sortable: true,
                  //     tdClass: ''
                  // },
                  {
                      label: 'Asset Type',
                      key: 'assetType',
                      sortable: true,
                      tdClass: ''
                  },
                  {
                      label: 'Manufacturer',
                      key: 'manufacturer',
                      sortable: true,
                      tdClass: ''
                  },
                  {
                      label: 'Model',
                      key: 'model',
                      sortable: true,
                      tdClass: ''
                  },
                  {
                      label: 'Quantity',
                      key: 'quantity',
                      sortable: false,
                      tdClass: 'text-center',
                      thClass: 'text-center'
                  },
                  // {
                  //     label: 'Quantity Used',
                  //     key: 'quantityUsed',
                  //     sortable: false,
                  //     tdClass: ''
                  // },
                  {
                      label: '',
                      key: 'show_details',
                      sortable: false,
                      tdClass: 'text-right'
                  }
              ],
          },
          stockItemTableData: {
              dataSource: [],
              isLoading: true,
              tableColumns: [
                  {
                      label: 'Asset Number',
                      key: 'assetNumber',
                      sortable: true,
                      tdClass: ''
                  },
                  // {
                  //     label: 'Asset Type',
                  //     key: 'assetType',
                  //     sortable: true,
                  //     tdClass: ''
                  // },
                  // {
                  //     label: 'Manufacturer',
                  //     key: 'manufacturer',
                  //     sortable: true,
                  //     tdClass: ''
                  // },
                  // {
                  //     label: 'Model',
                  //     key: 'model',
                  //     sortable: true,
                  //     tdClass: ''
                  // },
                  {
                      label: 'Quantity',
                      key: 'quantity',
                      sortable: false,
                      tdClass: 'text-center',
                      thClass: 'text-center'
                  },
                  {
                      label: 'Quantity Allocated',
                      key: 'quantityUsed',
                      sortable: false,
                      tdClass: 'text-center',
                      thClass: 'text-center'
                  },
                  {
                      label: 'Serial Number',
                      key: 'serialNumber',
                      sortable: true,
                      tdClass: ''
                  }
                  ,
                  {
                      label: '',
                      key: 'actions',
                      sortable: false,
                      tdClass: 'text-right'
                  }
              ],
          },
          stockItemTableDataSelected: {
              dataSource: [],
              isLoading: true,
              tableColumns: [
                  {
                      label: 'Asset Number',
                      key: 'assetNumber',
                      sortable: true,
                      tdClass: ''
                  },
                  
                  {
                      label: 'Quantity',
                      key: 'quantity',
                      sortable: false,
                      tdClass: 'text-center',
                      thClass: 'text-center'
                  },
                  {
                      label: 'Serial Number',
                      key: 'serialNumber',
                      sortable: true,
                      tdClass: ''
                  }
                  ,
                  {
                      label: '',
                      key: 'actions',
                      sortable: false,
                      tdClass: 'text-right'
                  }
              ],
          },

          ogStockList: [],
          ogClientStockList:[],
          stocksearch: {
            assetNumber: null,
            assetType: null
          },
          stockDetails: {
            ticketId: null,
            stockId: null,
            active: null,
          },
          selectedStockItem: null,
          selectedStockItemIndex: null,
          state: null
      }
  },
    mounted(){
      setTimeout(() => {
        this.tableData.dataSource = this.selectedJobCard.linkedStock
        this.tableData.isLoading = false
      }, 200)
    },
    methods:{
      ...mapActions(['searchBootstock','searchClientstock','AssignStockItem','RemoveStock']),
    
      onSelectStockType()
      {
        this.stocksearch.assetType = null;
        this.stocksearch.assetNumber = null;
        if (this.selectedStockType === StockTypes.Boot) {
          this.stockTableData.dataSource = this.ogStockList;
        }
        else if (this.selectedStockType === StockTypes.Client) {
          this.stockTableData.dataSource = this.ogClientStockList;
        }
      },
      onStockRowSelected(items)
      {
        this.selectedStock = items;
      },

      addStockToList(rowItem){
        let item =  {
          itemCode: null, 
          description: rowItem.model, 
          qty: 1, 
          price: null, 
          stockType: rowItem.assetType, 
          manufacturer: rowItem.manufacturer, 
          serialNumber: "26028", 
          model: rowItem.serialNumber, 
          locationId: null, 
          location: null, 
          statusId: null, 
          status: null, 
          active: true, 
          whdId: rowItem.whdId 
        }
        this.selectedJobCard.stocks.push(item)
        this.closeAddStockModal()
      },

      clearFilter(){
        this.stocksearch = {
          assetType: null,
          assetNumber: null
        }
        this.stockTableData.isLoading = true
        this.stockTableData.dataSource = this.ogStockList
        this.stockTableData.isLoading = false
      },
      searchStock(){
        this.stockTableData.isLoading = true;

        let assetType = this.stocksearch.assetType !== null ? this.stocksearch.assetType.toLowerCase() : null;
        let assetNumber = this.stocksearch.assetNumber !== null ? this.stocksearch.assetNumber.toLowerCase() : null;

        const selectedStockList = this.selectedStockType === StockTypes.Boot ?  this.ogStockList : this.ogClientStockList;

        this.stockTableData.dataSource = selectedStockList.filter(stock => {
          return (!assetType || stock.assetType.toLowerCase() === assetType) && (!assetNumber || stock.items.filter(item => item.assetNumber.toLowerCase() === assetNumber).length > 0);
        }).map(stock => {
          return {
            ...stock,
            items: assetNumber ? stock.items.filter(item => item.assetNumber.toLowerCase() === assetNumber) : stock.items
          };
        });

        this.stockTableData.isLoading = false;
      },
      openRemoveStockModal(rowItem) {
        this.$bvModal.show('removeStockModal')
        this.selectedStockItem = rowItem
        this.selectedStockItemIndex = this.tableData.dataSource.indexOf(rowItem)
      },
      closeRemoveStockModal() {
        this.$bvModal.hide('removeStockModal')
      },
      removeStockItem(){
        this.tableData.dataSource.splice(this.selectedStockItemIndex, 1)
        this.closeRemoveStockModal()
      },
      
      openAddStockModal() {
        this.stockTableData.isLoading = true
        this.selectedStockType = StockTypes.Boot
        this.$bvModal.show('addStockModal')
        this.searchBootstock()
        .then((res) => {
          console.log('response', res);
          this.ogStockList = res.data.result
          this.stockTableData.dataSource = res.data.result
          this.stockTableData.isLoading = false
          
        })

         this.searchClientstock()
        .then((res) => {
          //console.log('response', res);
          this.ogClientStockList = res.data.result
          // this.stockTableData.dataSource = res.data.result
          // this.stockTableData.isLoading = false
        })
      },
      closeAddStockModal() {
        this.$bvModal.hide('addStockModal')
      },

      addStockToTicket(item){
        if (  item.quantityUsed == 0)
          { 
            console.log("Cannot assign zero value")
            return;
          }
          console.log("Assigning value:", item.quantityUsed )
        let request = {
          'ticketId': this.selectedJobCard.ticketId,
          'clientId': this.selectedJobCard.clientId,
          'locationId': this.selectedJobCard.locationId,
          'assetId' :item.assetId,
          'qty' : item.quantityUsed
        }
console.log("request",request);

        this.$store.commit('setSelectedStock', request);
        this.state = 'loading';
        this.AssignStockItem().then(() => {
          const assetTypes = new Map(this.tableData.dataSource.map(stock => [stock.assetType, stock]));
console.log('assetTypes',assetTypes)

          //for (const newAsset of this.selectedStock) {
            const newAsset = item;
            const existingStock = assetTypes.get(newAsset.assetType);
console.log('newAsset',newAsset)
            if (!existingStock) {
              const newStock = this.stockTableData.dataSource.find(stock => stock.assetType == newAsset.assetType);
              newAsset.quantity = newAsset.quantityUsed;
              newStock.items = [newAsset];
              newStock.quantity = newAsset.quantityUsed;
              this.tableData.dataSource.push(newStock);
              assetTypes.set(newAsset.assetType, newStock);
            } else {
              existingStock.quantity += newAsset.quantityUsed;
              existingStock.items.push(newAsset);
            }
          //}
          this.selectedStock = [];
          this.closeAddStockModal()
          this.state = 'show';
        }
        )
        .catch(()=>{
          //console.log("Some issue")
          //do something or nothing
        })
      },

       removeStockFromTicket(){
         let request = {
          'ticketId': this.selectedJobCard.ticketId,
          'clientId': this.selectedJobCard.clientId,
          'locationId': this.selectedJobCard.locationId,
          'assetIds' : this.selectedStock.map(item => item.assetId)
        }

        this.$store.commit('setSelectedStock', request);
        this.state = 'loading';
        this.RemoveStock().then(() =>{
          this.tableData.dataSource = this.tableData.dataSource.filter(stock => {
            let removedItems = 0;
            stock.items = stock.items.filter(item => {
              if (request.assetIds.includes(item.assetId)) {
                removedItems++;
                return false;
              }
              return true;
            });
            stock.quantity -= removedItems;
            return stock.items.length > 0;
          });
          //save the ticket 
          //this.closeAddStockModal()
          this.state = 'show';
        }
        )
        .catch(()=>{
          //console.log("Some issue")
          //do something or nothing
        })
      },

    },
    computed:{
        ...mapState(['selectedJobCard']),
        rows() {
          return this.tableData.dataSource.length
        },
        StockTypes() {
          return StockTypes;
        }
    }
}
</script>