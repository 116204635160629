<template>
    <div>
        <b-row>
            <b-col cols="12" class="text-right">
                <b-button variant="primary" @click="createNewQuote()" :disabled="isStatusClosed">Add Quote</b-button>
            </b-col>
        </b-row>


        <b-row>
            <b-col cols="12">
                <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" :busy="tableData.isLoading"
                sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                    
                            
                    <template #cell(quoteDate)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.quoteDate | dateTimeFilter}}</span>
                        </b-row>
                    </template>
                    <template #cell(total)="row">
                        R {{row.item.total.toFixed(2)}}
                    </template>
                    
                    <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">
                            <b-button @click="copyDocument(row.item)" variant="primary" size="sm" class="mr-2" :disabled="isStatusClosed">Copy
                            </b-button>
                            <b-button @click="viewDocument(row.item)" variant="primary" size="sm">View
                            </b-button>
                        </b-row>
                    </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
                <b-pagination
                v-model="tableData.currentPage"
                :total-rows="tableData.rows"
                :per-page="tableData.perPage"
                ></b-pagination>
        </b-row>

        

    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: {
        isStatusClosed: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Quotation Number',
                    key: 'quoteNumber',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Quotation Type',
                    key: '',
                    sortable: true,
                    tdClass: ''
                },
                // {
                //     label: 'Ticket Number',
                //     key: 'ticketId',
                //     sortable: true,
                //     tdClass: ''
                // },
                {
                    label: 'Date Quoted',
                    key: 'quoteDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Created By',
                    key: 'createdBy',
                    sortable: true,
                    tdClass: ''
                },
               {
                    label: 'Status',
                    key: 'status',
                    sortable: true,
                    tdClass: ''
                },
                 {
                    label: 'Total Excl VAT',
                    key: 'total',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        this.tableData.dataSource = this.selectedJobCard.budgetaryQuotes
        this.tableData.isLoading = false
    },
    methods:{
        ...mapActions(['createQuote', 'copyQuote']),
        createNewQuote(){
            let request = {
                ticketId : this.selectedJobCard.referenceNumber
            }
            this.$store.commit('setQuoteCreateRequest', request)
            this.createQuote()
            .then((res) => {
                this.selectedJobCard.budgetaryQuotes.push(res.data)
                this.$store.commit('setSelectedQuote', res.data)
                this.$store.commit('setIsQuoteEditable', !this.isStatusClosed)
                this.$router.push({path: '/jobCards/quoteView'})
            })
        },
        copyDocument(rowItem){
            //console.log('rowITem', rowItem)
            this.$store.commit('setCopyQuoteIdRequest', rowItem.budgetaryQuoteId)
            this.copyQuote()
            .then((res) => {
                //console.log('response', res)
                this.selectedJobCard.budgetaryQuotes.push(res.data)
            })
        },
        viewDocument(rowItem){
            this.$store.commit('setSelectedQuote', rowItem)
            this.$store.commit('setIsQuoteEditable', !this.isStatusClosed)
            this.$router.push({path: '/jobCards/quoteView'})
        }
    },
    computed:{
        ...mapState(['selectedJobCard'])
    }
}
</script>