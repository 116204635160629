<template>
    <div>
        <b-row>
            <b-col class="text-right">
                <b-button variant="primary" @click="openAddLabourModal()" :disabled="isStatusClosed">Add</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table striped hover 
                         :items="selectedJobCard.labours" 
                         :fields="tableData.tableColumns" 
                         :busy="tableData.isLoading"
                         id="labourTable"
                         :per-page="tableData.resultsPerPage"
                         :current-page="tableData.currentPage"
                         sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(startDate)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.startDate | dateTimeFilter}}</span>
                        </b-row>
                    </template>

                    <template #cell(endDate)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.endDate | dateTimeFilter}}</span>
                        </b-row>
                    </template>

                    <template #cell(logDate)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.logDate | dateTimeFilter}}</span>
                        </b-row>
                    </template>

                    
                    
                    <template slot="bottom-row">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <p class="mb-0 bold">Total Hours</p>
                        </td>
                        <td class="">
                            <p class="mb-0">{{totalHours}}</p>
                        </td>
                        <td></td>
                        <td></td>
                    </template>


                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="labourTable"
                ></b-pagination>
        </b-row>
        <!-- id="add-time-modal"  -->
        <b-modal v-model="showAddLabour" size="md" hide-footer no-header @close="closeAddTimeModal()">
            <b-row>
                <b-col>
                    <h4>Book time</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>{{ formData.date.label }}</label>
                    <b-form-datepicker v-model="formData.date.value" @input="validateModalForm"></b-form-datepicker>
                    <span v-if="formData.date.error" class="text-red font-weight-400 text-left">{{ formData.date.error }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>{{ formData.workTypeId.label }}</label>
                    <b-form-select v-model="formData.workTypeId.value" @change="validateModalForm">
                        <b-form-select-option v-for="(item, index) in workTypes" :key="index" :value="item.workTypeId">{{item.description}}</b-form-select-option>
                    </b-form-select>
                    <span v-if="formData.workTypeId.error" class="text-red font-weight-400 text-left">{{ formData.workTypeId.error }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label>{{ formData.ticketReferenceNo.label }}</label>
                    <b-form-input disabled v-model="formData.ticketReferenceNo.value" @input="validateModalForm"></b-form-input>
                    <span v-if="formData.ticketReferenceNo.error" class="text-red font-weight-400 text-left">{{ formData.ticketReferenceNo.error }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="no-back" class="link-btn mr-2" size="sm" @click="isBulkTime = true; formData.from.value = null; formData.to.value = null;                             
                            removeRequiredField('from');
                            removeRequiredField('to');
                            addRequiredField('hours');">Add Bulk Time</b-button>
                    <b-button variant="no-back" class="link-btn" size="sm" @click="isBulkTime = false; formData.hours.value = 0;                             
                    addRequiredField('from');
                            addRequiredField('to');
                            removeRequiredField('hours');">Add Specific Time</b-button>
                </b-col>
            </b-row>
            <div v-if="isBulkTime">
                <b-row>
                    <b-col>
                        <label>Hours Worked</label>
                        <b-form-input v-model="formData.hours.value" type="number" @input="validateModalForm" :formatter="numberFormat"></b-form-input>
                        <span v-if="formData.hours.error" class="text-red font-weight-400 text-left">{{ formData.hours.error }}</span>                   
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(0.25)">+0:15</b-button>
                        <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(0.5)">+0:30</b-button>
                        <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(1)">+1:00</b-button>
                        <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(2)">+2:00</b-button>
                        <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(4)">+4:00</b-button>
                        <b-button variant="no-back" class="link-btn ml-2" size="sm" @click="addTimeToBulkValue(8)">+8:00</b-button>
                    </b-col>
                </b-row>
            </div>
            <div v-if="!isBulkTime">
                <b-row>
                    <b-col>
                        <label>{{ formData.from.label }}</label>
                        <b-form-timepicker v-model="formData.from.value" @input="validateHours" locale="en"></b-form-timepicker>
                        <span v-if="formData.from.error" class="text-red font-weight-400 text-left">{{ formData.from.error }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>{{ formData.to.label }}</label>
                        <b-form-timepicker v-model="formData.to.value" @input="validateHours" locale="en" ></b-form-timepicker>
                        <span v-if="formData.to.error" class="text-red font-weight-400 text-left">{{ formData.to.error }}</span>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col>
                    <label>{{ formData.comment.label }}</label>
                    <b-form-textarea v-model="formData.comment.value" @input="validateModalForm"></b-form-textarea>
                    <span v-if="formData.comment.error" class="text-red font-weight-400 text-left">{{ formData.comment.error }}</span>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" @click="clearValues">Clear</b-button>
                    <b-button variant="primary" @click="doAddTimeTo()">Add Time</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: {
        isStatusClosed: {
            type: Boolean,
            required: true
        }
    },
    state: 'show',  
    data: () => ({
        isBulkTime: false,
        showAddLabour: false,
        workTypes: [],
        requiredFields: ["workTypeId", "comment", "date", "from", "to"],
        formData: {
            workTypeId: {
                label: 'Type of work',
                value: null,
                error: "",
            },
            comment: {
                label: 'Notes',
                value: null,
                error: "",
            },
            date: {
                label: 'Date',
                value: null,
                error: "",
            },
            from: {
                label: 'From',
                value: null,
                error: "",
            },
            to: {
                label: 'To',
                value: null,
                error: "",
            },
            hours: {
                label: 'Hours Worked',
                value: 0,
                error: "",
            },
            ticketReferenceNo: {
                label: 'Ticket',
                value: null,
                error: "",
            }
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Technician Name',
                    key: 'user',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Response Type',
                    key: 'responseType',
                    sortable: false,
                    tdClass: '',
                },
                {
                    label: 'Start Date',
                    key: 'startDate',
                    sortable: false,
                    tdClass: '',
                },
                {
                    label: 'End Date',
                    key: 'endDate',
                    sortable: false,
                    tdClass: '',
                },
                {
                    label: 'Hours Worked',
                    key: 'hours',
                    sortable: false,
                    tdClass: '',
                },
                
                {
                    label: 'Notes',
                    key: 'description',
                    sortable: false,
                    tdClass: '',
                },
                {
                    label: 'Date of Entry',
                    key: 'logDate',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
    }),
    mounted(){
        setTimeout(() => {
            this.tableData.isLoading = false
            this.formData.ticketReferenceNo.value = this.selectedJobCard.referenceNumber
        },300)
    },
    created(){
        this.loadWorkTypes(0)
        .then((res) => {
            this.workTypes = res.data
        })
        .catch(() => {
            this.state = 'show'
        })
    },
    methods:{
        ...mapActions(['saveTimesheet', 'loadWorkTypes'
                        , 'getJobCardLabour', 'getJobCardNotes']),
        ...mapActions('notificationService', ['addWarningMessage']),
        
        numberFormat(value) {
        return value.toFixed(2);
        },

        clearValues(){
            this.formData.date.value = null;
            this.formData.date.error = "";
            this.formData.from.value = null;
            this.formData.from.error = "";
            this.formData.to.value = null;
            this.formData.to.error = "";
            this.formData.comment.value = null;
            this.formData.comment.error = "";
            this.formData.hours.value = 0;
            this.formData.hours.error = "";
            this.formData.workTypeId.value = null;
            this.formData.workTypeId.error = "";
            // this.formData.ticketReferenceNo.value = null;
            // this.formData.ticketReferenceNo.error = "";
        },
        validateModalForm() {
            for (const field in this.formData) {
                if (this.requiredFields.includes(field) && !this.formData[field].value) {
                    this.formData[field].error = `${this.formData[field].label} is required.`;
                } else {
                    if (field.toLowerCase() != this.formData.to.label.toLowerCase()) {
                        this.formData[field].error = ``;
                    }
                }
            }
            this.isRequired = true;
        },
        validateHours(){
            this.validateModalForm();

            if (this.formData.to.value) {
                if (this.formData.to.value <= this.formData.from.value) {
                    this.formData.to.error = `${this.formData.to.label} must be later than ${this.formData.from.label}.`;
                } else if (this.formData.to.value) {
                    this.formData.to.error = ``;
                }
            }
        },
        openAddLabourModal(){
            this.clearValues();
            this.showAddLabour = true;
            // this.$bvModal.show('add-time-modal');
        },
        closeAddTimeModal(){
            //console.log("Closing Modal")
            this.clearValues();
            this.showAddLabour = false;
            // this.$bvModal.hide('add-time-modal')
        },
        addTimeToBulkValue(timeAdded){
            var newTime = parseFloat(this.formData.hours.value) + parseFloat(timeAdded)
            this.formData.hours.value = newTime
            this.validateModalForm();
        },
        removeRequiredField(field) {
            const index = this.requiredFields.indexOf(field);
            if (index > -1) {
                this.requiredFields.splice(index, 1);
                this.formData[field].error = ""
            }
        },
        addRequiredField(value) {
            const index = this.requiredFields.indexOf(value);
            if (index < 0) {
                this.requiredFields.push(value);
            }
        },
        isFormDataValid() {
            return  Object.values(this.formData).find(value => value.error !== '') == null;
        },
        doAddTimeTo(){
            this.validateModalForm()

            if (this.isFormDataValid()) {
                let fromDate = null
            let toDate = null
            let request = {
                date: this.formData.date.value,
                workTypeID: this.formData.workTypeId.value,
                description: this.formData.comment.value,
                hours: this.formData.hours.value,
                fromDateTime: null,
                toDateTime: null,
                ticketId: this.selectedJobCard.ticketId,
                clientId: this.selectedJobCard.clientId,
                ticketReferenceNo: this.formData.ticketReferenceNo.value
            }
            
            //console.log("Isbultime:",this.isBulkTime)

            if (!this.isBulkTime) {
                let a = new Date(this.formData.date.value)
                let b = new Date(this.formData.date.value)

                a.setHours(this.formData.to.value.substr(0,2))
                a.setMinutes(this.formData.to.value.substr(3,2))
                a.setHours(a.getHours()+2)
                toDate = a
                request.toDateTime = a

                b.setHours(this.formData.from.value.substr(0,2))
                b.setMinutes(this.formData.from.value.substr(3,2))
                b.setHours(b.getHours()+2)
                //console.log(b);
                fromDate = b
                request.fromDateTime = b

                //console.log("FromDate:",(b))
                //console.log("ToDate:", (a))

                let ta = (new Date(a)).getTime()
                let tb = (new Date(b)).getTime()
                let m = ta-tb
                let diff =m / 1000
                diff = diff / 60
                let mins = Math.abs(Math.round(diff))
                this.formData.hours.value = (mins / 60).toFixed(2)
                // this.formData.hours.value = this.diff_minutes(a,b) / 60
                request.hours = this.formData.hours.value
            } else {
                //add something that calculates the minutes and then adds it
                request.hours = this.formData.hours.value
            }

            this.$store.commit('setSaveTimesheetRequest', request)
            this.saveTimesheet()
            .then(() => {
                 if (!this.isBulkTime) {
                    toDate.setHours(toDate.getHours()-2)
                    fromDate.setHours(fromDate.getHours()-2)
                 }
                //console.log('asda', this.workTypes.find(item => item.workTypeId === request.workTypeID))
                this.selectedJobCard.labours.unshift({
                    description: request.description,
                    endDate: toDate,
                    hours: request.hours,
                    logDate: new Date(),
                    responseType: (this.workTypes.find(item => item.workTypeId === request.workTypeID)).description,
                    responseTypeId: request.workTypeID,
                    startDate: fromDate,
                    user: localStorage.getItem('user'),
                    ticketId: request.ticketId,
                    clientId: request.clientId
                });
                //console.log("refresh data");
                this.getSelectedJobCardNotes();
                this.getSelectedJobCardLabour();
                this.closeAddTimeModal()
            })
            } else {
                // TODO
            }
        },
        getSelectedJobCardNotes() {
            //console.log("get notes");

            if (this.$store.state.selectedJobCard === null) {
                this.goBackToSearch();
            }
            let request = {
                ticketId: this.selectedJobCard.ticketId,
            };
            this.$store.commit("setJobCardIdRequest", request);
            this.getJobCardNotes().then(() => {
//console.log("notes", this.selectedJobCard.notes );
              
            });
            //console.log("got notes");
        },
        getSelectedJobCardLabour() {
            if (this.$store.state.selectedJobCard === null) {
                this.goBackToSearch();
            }
            let request = {
                ticketId: this.selectedJobCard.ticketId,
            };
            this.$store.commit("setJobCardIdRequest", request);
            this.getJobCardLabour().then(() => {
                this.$store.commit("setSelectedJobCard", this.selectedJobCard);
                this.state = "show";
            });
        }
    },
    computed:{
        ...mapState(['selectedJobCard']),
        rows() {
            return this.selectedJobCard.labours.length
        },
        totalHours(){
            let total = 0
            if(this.selectedJobCard.labours === undefined) return;
            for (let i = 0; i < this.selectedJobCard.labours.length; i++) {
                const element = this.selectedJobCard.labours[i];
                total += parseFloat(element.hours)
            }

            let str = total.toFixed(2)
            let float = parseInt(str.substring(str.indexOf('.')+1))
            if (float > 0 && float < 26) total = Math.trunc(total) + 0.25
            if (float > 25 && float < 51) total = Math.trunc(total) + 0.5
            if (float > 50 && float < 76) total = Math.trunc(total) + 0.75
            if (float > 75 && float < 100) total = Math.trunc(total) + 1
            
            return total.toFixed(2)
        },
        diff_minutes(dt2, dt1) {
            let diff =((new Date(Date(dt2))).getTime()-(new Date(Date(dt1))).getTime()) / 1000;
            diff /= 60;
            return Math.abs(Math.round(diff));
        }
        // parseDate(input) {
        //     var parts = input.match(/(\d+)/g);
        //     // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
        //     return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
        // }
    }
}
</script>