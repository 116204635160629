<template>
    <div>
      <b-row>
        <b-col class="text-right">
          <b-button variant="primary" squared class="ml-2" @click="openAddEquipmentModal" :disabled="isStatusClosed">Add Travel</b-button>
        </b-col>
      </b-row>
        <b-row>
            <b-col cols="12">
                <b-table striped hover
                         :items="tableData.dataSource" 
                         :fields="tableData.tableColumns" 
                         :busy="tableData.isLoading"
                         :per-page="tableData.resultsPerPage"
                         id="equipmentTable"
                         :current-page="tableData.currentPage"
                         :foot-clone="true"
                         sort-icon-left>

                  <template #table-busy>
                      <div class="text-center my-2">
                          <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                      </div>
                  </template>
                  <template #cell(distance)="row">
                    {{row.item.vehicleOdometerEnd - row.item.vehicleOdometerStart}} km
                  </template>
                  
                  <template #cell(timeFrom)="row">
                    {{row.item.timeFrom | dateTimeFilter}}
                  </template>


                  <template #cell(timeTo)="row">
                    {{row.item.timeTo | dateTimeFilter}}
                  </template>

                  <template #cell(travelTime)="row">
                    {{ travelHours(row.item.travelTime) }} hours 
                  </template>

                  <template #cell(actions)="row">
                      <b-row align-h="end">
                          <b-button @click="openRemoveEquipmentModal(row.item)" variant="red" size="sm" class="ml-2" :disabled="isStatusClosed">
                            Remove
                          </b-button>
                      </b-row>
                  </template>

                  <template #foot()>
                    <i></i>
                  </template>

                  <template #foot(distance)>
                    <i>{{ totalDistance }}</i>
                  </template>
                  
                  <template #foot(travelTime)>
                    <i>{{ totalTime }} hours</i>
                  </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
                <b-pagination
                v-model="tableData.currentPage"
                :total-rows="equipmentTableRows"
                :per-page="tableData.resultsPerPage"
                aria-controls="equipmentTable"
                ></b-pagination>
        </b-row>

      <b-modal class="p-0" id="addEquipmentModal" size="lg" hide-footer title="Add Vehicle Reading" @close="closeAddEquipmentModal">
        <b-row v-if="this.amessage!=null">
          <b-col cols="12">
            <b-form-input disabled v-model="amessage" style="backgroun:beiged"></b-form-input>
          </b-col>
        </b-row>
        <b-row v-if="!isActive">
          <b-col cols="4">
            <label>Vehicle Search <span class="text-red">*</span></label>
            <b-input-group>
              <b-form-input disabled v-model="equipmentDetails.reference"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" @click="doVechicleSearch">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="4">
            <label>Start Odometer <span class="text-red">*</span></label>
            <b-form-input type="number" v-model="equipmentDetails.vehicleOdometerStart"></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>End Odometer <span class="text-red">*</span></label>
            <b-form-input type="number" v-model="equipmentDetails.vehicleOdometerEnd"></b-form-input>
          </b-col>
        </b-row>

        <b-row v-if="!isActive">
          
          <b-col cols="4">
            <label>Date <span class="text-red">*</span></label>
            <b-form-datepicker v-model="equipmentDetails.travelDate"></b-form-datepicker>
          </b-col>
          <b-col>
              <label>Time From <span class="text-red">*</span></label>
              <b-form-timepicker v-model="equipmentDetails.timeFrom" locale="en"></b-form-timepicker>
          </b-col>
          <b-col>
              <label>Time To <span class="text-red">*</span></label>
              <b-form-timepicker v-model="equipmentDetails.timeTo" locale="en"></b-form-timepicker>
          </b-col>
        </b-row>

        <b-row v-if="!isActive">
          <b-col cols="12">
            <label>Notes <span class="text-red">*</span></label>
            <b-form-input v-model="equipmentDetails.reason"></b-form-input>
          </b-col>
        </b-row>

        <b-row v-if="isActive">
          <b-table striped hover
                   :items="equipmentTableData.dataSource"
                   :fields="equipmentTableData.tableColumns"
                   :busy="equipmentTableData.isLoading"
                   :per-page="equipmentTableData.resultsPerPage"
                   @row-clicked="addItem"
                   id="materialSearchTable"
                   :current-page="equipmentTableData.currentPage"
                   sort-icon-left>

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
              </div>
            </template>


            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button size="sm" class="btn-icon" @click="addItem(row.item)">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </b-button>
              </b-row>
            </template>
          </b-table>
        </b-row>
        <b-row v-if="isActive" align-h="center" >
          <b-pagination
              v-model="equipmentTableData.currentPage"
              :total-rows="equipmentRows"
              :per-page="equipmentTableData.resultsPerPage"
              aria-controls="materialSearchTable"
          ></b-pagination>
        </b-row>
        
        <hr class="mx-3">
        
        <b-row v-if="!isActive">
          <b-col>
            <div class="d-flex justify-content-end">
              <div>
                <b-button variant="red" squared class="ml-2" @click="closeAddEquipmentModal">Cancel</b-button>
              </div>
              <div>
                <b-button variant="primary" squared class="ml-2" @click="addEquipmentItem">Done</b-button>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="isActive">
          <b-col>
            <div class="d-flex justify-content-end">
                <b-button variant="red" squared class="" @click="toggleItemSearch">Cancel</b-button>
              <!-- <div>
                <b-button variant="primary" squared class="ml-2">Add</b-button>
              </div> -->
            </div>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal class="p-0" id="removeEquipmentModal" size="md" hide-footer title="Remove Vehicle Reading" @close="closeRemoveEquipmentModal">
        <b-row>
          <b-col cols="12">
            <label>Are you sure you wish to delete <span v-if="this.selectedEquipment !== null" class="text-red font-weight-bold">{{this.selectedEquipment.vehicleReference}}</span> reading?</label>
          </b-col>
        </b-row>
        <hr class="mx-3">
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <div>
                <b-button variant="red" squared class="ml-2" @click="removeStockItem">Remove</b-button>
              </div>
              <div>
                <b-button variant="red" squared class="ml-2" @click="closeRemoveEquipmentModal">Cancel</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
export default {
    props: {
      isStatusClosed: {
        type: Boolean,
        required: true
      }
    },  
    data: () => ({
      // totalDistance:0,
      dtoTravelDetail : {
        itemId: null,
        vehicleOdometerStart: null,
        vehicleOdometerEnd : null,
        detailDate: null,
        detailEndDate: null,
        reference: null,
        description: null,

      },
      amessage:null,
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: false,
            tableColumns: [
                {
                    label: 'Vehicle Reference',
                    key: 'reference',
                    sortable: false,
                    tdClass: ''
                },
                // {
                //     label: 'Vehicle Description',
                //     key: 'description',
                //     sortable: false,
                //     tdClass: ''
                // },
                {
                    label: 'Start Odometer',
                    key: 'vehicleOdometerStart',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'End Odometer',
                    key: 'vehicleOdometerEnd',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Distance',
                    key: 'distance',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Start Date',
                    key: 'timeFrom',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'End Date',
                    key: 'timeTo',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Time',
                    key: 'travelTime',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'text-right'
                }
            ]
        },
        equipmentDetails: {
          itemCode: null,
          ticketId: null,
          serialNumber: null,
          vehicleOdometerStart: null,
          vehicleOdometerEnd: null,
          timeTo: null,
          timeFrom: null,
          travelDate: null,
          travelTime:null,
          reason: null
        },
        equipmentTableData: {
          resultsPerPage: 10,
          currentPage: 1,
          dataSource: [],
          isLoading: false,
          tableColumns: [
            {
              label: 'Vehicle Reference',
              key: 'serialNumber',
              sortable: true,
              tdClass: ''
            },
            {
              label: 'Description',
              key: 'description',
              sortable: true,
              tdClass: ''
            },
            {
              label: '',
              key: 'actions',
              sortable: false,
              tdClass: ''
            }
          ]
        },
        selectedEquipment: null,
        selectedEquipmentIndex: null,
        isActive: false,
    }),
    mounted(){
      setTimeout(() => {
        this.tableData.isLoading = true
        // TODO
        //console.log("Travel", this.selectedJobCard.equipmentUsed)
        this.tableData.dataSource = this.selectedJobCard.equipmentUsed
        this.tableData.isLoading = false
      },1000)
    },
    methods:{
      ...mapActions(['getAvailableVehicles','addTravelToTicket','getTravelForTicket','getJobCardNotes','deleteTravel']),
      // ...mapActions('notificationService', ['addWarningMessage']),

      openAddEquipmentModal() {
        this.clearEquipmentDetails();
        this.$bvModal.show ('addEquipmentModal');
      },
      closeAddEquipmentModal () {
        this.$bvModal.hide('addEquipmentModal')
      },
      openRemoveEquipmentModal(rowItem) {
        this.$bvModal.show ('removeEquipmentModal')
        this.selectedEquipment = rowItem
        this.selectedEquipmentIndex = this.tableData.dataSource.indexOf(rowItem)
      },
      closeRemoveEquipmentModal () {
        this.$bvModal.hide('removeEquipmentModal')
      },
      addItem(rowItem) {
        this.equipmentDetails.itemCode = rowItem.itemCode
        this.equipmentDetails.reference = rowItem.serialNumber
        this.isActive = false
      },
      
      showMessage(themessage){
        this.amessage = themessage;
      },
      isFormValid() {
        if (this.equipmentDetails.itemCode === null) {
          this.showMessage('Vehicle is a required field, please select it to continue')
          return false;
        }

        if (this.equipmentDetails.vehicleOdometerStart === null 
            || this.equipmentDetails.vehicleOdometerEnd === null) {
          this.showMessage('Odometer readings are a required fields, please enter them to continue')
          return false;
        }
//console.log(this.equipmentDetails.vehicleOdometerStart >= this.equipmentDetails.vehicleOdometerEnd)
        if (this.equipmentDetails.vehicleOdometerStart >= this.equipmentDetails.vehicleOdometerEnd) {
          this.showMessage('Odometer start should be smaller than odometer end, please enter valid readings to continue')
          return false;
        }

        if (this.equipmentDetails.travelDate === null) {
          this.showMessage('Date is a required field, please enter it to continue')
          return false;
        }

        if (this.equipmentDetails.timeTo === null
            || this.equipmentDetails.timeFrom === null) {
          this.showMessage('Time From and To are required fields, please enter them to continue')
          return false;
        } else {
            const today = new Date();
            const dateString = today.toISOString().slice(0, 10);
            const timeFrom = new Date(`${dateString}T${this.equipmentDetails.timeFrom}Z`);
            const timeTo = new Date(`${dateString}T${this.equipmentDetails.timeTo}Z`);
            const timeDifferenceInMs = timeTo - timeFrom;

            if (timeDifferenceInMs == 0 || timeDifferenceInMs < 0) {
              this.showMessage('Time From must be earlier than Time To, please correct them to continue')
              return false;
            }
        }

        if (this.equipmentDetails.reason === null) {
          this.showMessage('Reason is a required field, please enter it to continue')
          return false;
        }

        return true;
      },
      addEquipmentItem() {
        if (this.isFormValid()) {
            const today = new Date();
            const dateString = today.toISOString().slice(0, 10);
            const timeFrom = new Date(`${dateString}T${this.equipmentDetails.timeFrom}Z`);
            const timeTo = new Date(`${dateString}T${this.equipmentDetails.timeTo}Z`);
            const timeDifferenceInMs = timeTo - timeFrom;

            this.equipmentDetails.timeFrom = timeFrom;
            this.equipmentDetails.timeTo = timeTo;
            this.equipmentDetails.travelTime = this.travelHours(Math.floor(timeDifferenceInMs / (1000 * 60))*60);
            this.equipmentDetails.ticketId = this.selectedJobCard.ticketId
            //this.tableData.dataSource.push(this.equipmentDetails)
            this.saveTravel();
            this.clearEquipmentDetails();
            this.closeAddEquipmentModal()
        }
      },
      clearEquipmentDetails() {
        this.amessage = null;
        this.equipmentDetails = {
          itemCode: null,
          ticketId: null,
          serialNumber: null,
          vehicleOdometerStart: null,
          vehicleOdometerEnd: null,
          timeTo: null,
          timeFrom: null,
          travelDate: null,
          reason: null
        }
      },
      toggleItemSearch() {
        this.isActive = !this.isActive
      },
      doVechicleSearch(){
        this.isActive = !this.isActive
        this.getAvailableVehicles()
        .then((res) => {
          //console.log('res for getConsumablesEquipment', res)
          this.equipmentTableData.dataSource = res.data
        })
      },

      removeStockItem() {

        console.log(this.selectedEquipment)
        console.log(this.selectedEquipment.equipmentId)

        let request = {
          ticketId: this.selectedJobCard.ticketId,
          equipmentId: this.selectedEquipment.equipmentId

            };

        this.$store.commit("removetravelRequest", request);

        this.deleteTravel()

        this.tableData.dataSource.splice(this.selectedEquipmentIndex,1)         

        this.closeRemoveEquipmentModal()
      },

      travelHours(travelMinutes){
        //console.log("Minutes",travelMinutes)
        let str = (travelMinutes/ 60).toFixed(2)
        let float = parseInt(str.substring(str.indexOf('.')+1))
        if (float == 0)travelMinutes=travelMinutes/60;
        if (float > 0 && float < 26) travelMinutes = Math.trunc(travelMinutes/60) + 0.25
        if (float > 25 && float < 51) travelMinutes = Math.trunc(travelMinutes/60) + 0.5
        if (float > 50 && float < 76) travelMinutes = Math.trunc(travelMinutes/60) + 0.75
        if (float > 75 && float < 100) travelMinutes = Math.trunc(travelMinutes/60) + 1
        
        
        //console.log("Hours",travelMinutes)
        return travelMinutes.toFixed(2)

      },

      saveTravel()
      {
        
        this.dtoTravelDetail.itemId = this.equipmentDetails.itemCode
        this.dtoTravelDetail.vehicleOdometerStart = this.equipmentDetails.vehicleOdometerStart
        this.dtoTravelDetail.vehicleOdometerEnd = this.equipmentDetails.vehicleOdometerEnd
        this.dtoTravelDetail.detailDate = this.formatDate(this.equipmentDetails.travelDate , this.equipmentDetails.timeFrom)
        this.dtoTravelDetail.detailEndDate = this.formatDate(this.equipmentDetails.travelDate,this.equipmentDetails.timeTo)
        this.dtoTravelDetail.description = this.equipmentDetails.reason
        this.dtoTravelDetail.reference = this.equipmentDetails.reference
        console.log('this.dtoTravelDetail',this.dtoTravelDetail)

        this.$store.commit("setJobCardIdRequest", this.selectedJobCard.ticketId);
        this.$store.commit("setTravelItemRequest", this.dtoTravelDetail);
        
        this.addTravelToTicket().then((res) => {
          console.log('travel added',res)
          // get the checlists from the database
          this.getTravelItems();
          this.getSelectedJobCardNotes();
          this.state = "show";
        }).catch((err) => {
          console.log('travel add error',err)
        })
      },

      getTravelItems(){
          this.getTravelForTicket().then(() => {
           // this.$store.commit("setSelectedJobCard", this.selectedJobCard);
               
            console.log('this.selectedJobCard.equipmentUsed',this.selectedJobCard.equipmentUsed)
            this.$store.commit("setSelectedJobCard", this.selectedJobCard);
            this.tableData.dataSource = this.selectedJobCard.equipmentUsed

        })
      },

      getSelectedJobCardNotes() {
            
        if (this.$store.state.selectedJobCard === null) {
                this.goBackToSearch();
            }
            let request = {
                ticketId: this.selectedJobCard.ticketId,
            };
            this.$store.commit("setJobCardIdRequest", request);
            this.getJobCardNotes().then(() => {
//console.log("notes", this.selectedJobCard.notes );
this.$store.commit("setSelectedJobCard", this.selectedJobCard);
            });
            //console.log("got notes");
        },

        formatDate(adate, atime) {
          
          let a = new Date(adate)
          a.setHours(atime.getHours())
          a.setMinutes(atime.getMinutes())
          //a.setHours(a.getHours()+2)

          //const d = (new Date(atime))
          //var time = d.toLocaleTimeString();
          //const date = new Date(adate.toString() + 'T' + time);
          return a;
        },
        padTo2Digits(num) {
          return num.toString().padStart(2, '0');
        }
    },
    computed:{
        ...mapState(['selectedJobCard']),
        equipmentTableRows() {
          return this.tableData.dataSource.length
        },  
        equipmentRows() {
          return this.equipmentTableData.dataSource.length
        },
        totalDistance: function() {
          return this.tableData.dataSource.reduce(function(a, c){return a + Number((c.vehicleOdometerEnd - c.vehicleOdometerStart) || 0)}, 0) + " km"
        },
        totalTime: function() {
          
          let travelMinutes =  this.tableData.dataSource.reduce(function(a, c){return a + (Number((c.travelTime)) || 0)}, 0)
           
          //console.log("Minutes",travelMinutes)
          let str = (travelMinutes/ 60).toFixed(2)
          let float = parseInt(str.substring(str.indexOf('.')+1))
          if (float == 0)travelMinutes=travelMinutes/60;
          if (float > 0 && float < 26) travelMinutes = Math.trunc(travelMinutes/60) + 0.25
          if (float > 25 && float < 51) travelMinutes = Math.trunc(travelMinutes/60) + 0.5
          if (float > 50 && float < 76) travelMinutes = Math.trunc(travelMinutes/60) + 0.75
          if (float > 75 && float < 100) travelMinutes = Math.trunc(travelMinutes/60) + 1
          
          
          //console.log("Hours",travelMinutes)
          return travelMinutes.toFixed(2)

          //return this.tableData.dataSource.reduce(function(a, c){return a + self.travelHours(Number((c.travelTime)) || 0).toFixed(2)}, 0) + " hours"
        //return this.travelHours(44);
        }
    },
    
}
</script>